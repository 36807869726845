import { CircularProgress, CssBaseline } from '@mui/material'
import React, { Suspense } from 'react'
import Header from '../../components/dashboard/Header'
import { Outlet } from 'react-router-dom'
import { InverterProvider } from '../../context/InverterContext'
import { WmcProvider } from '../../context/WmcContext'
import { PvMonitoringProvider } from '../../context/PvMonitoringContext'
import SuperUserHeader from '../../components/SuperUser/general/SuperUserHeader'
import { PlantListProvider } from '../../context/super_user_context/PlantListContext'
export default function SuperUserRoute() {
    return (
        <SuperUserHeader>
            <PlantListProvider>

                <Suspense
                    fallback={
                        <>
                            <CssBaseline />
                            <CircularProgress sx={{ alignSelf: 'center', color: 'primary.main' }} />
                        </>
                    }
                >
                    <Outlet />
                </Suspense>

            </PlantListProvider>
        </SuperUserHeader>

    )
}

