import React, { memo } from 'react'
import { AuthenticationProvider } from './context/AuthenticationContext'
import MainRouter from './routes/MainRouter'
import './App.css'
import { BrowserRouter } from 'react-router-dom'
import GetTheme from './Themes/MyTheme'
import { ThemeProvider } from '@mui/material'

function App() {
  const { theme, themeVariables } = GetTheme();
  return (
    <BrowserRouter>
      <AuthenticationProvider>
        <ThemeProvider theme={theme}>
          <div style={themeVariables}>
            <MainRouter />
          </div>
        </ThemeProvider>
      </AuthenticationProvider>
    </BrowserRouter>

  )
}
export default memo(App)

