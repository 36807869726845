import { AccountCircle, Person, SolarPower } from '@mui/icons-material'
import { Box, Button, Card, CardActions, Container, CssBaseline, Grid, Icon, IconButton, InputBase, Stack, Typography, useTheme } from '@mui/material'
import React from 'react'
import RecordList from '../../../components/SuperUser/dashboard/RecordList'
import Image from 'mui-image'
import GoogleMapReact from 'google-map-react';
import { Loader } from '@googlemaps/js-api-loader'
import InverterList from '../../../components/SuperUser/InverterList'
function GisLocation() {
    const { palette, spacing, shape: { borderRadius } } = useTheme()
    const AnyReactComponent = ({ text }) => <div>{text}</div>;

    const position = [51.505, -0.09]
    const defaultProps = {
        center: {
            lat: 10.99835602,
            lng: 77.01502627
        },
        zoom: 11
    };

    return (
        <Box>
            <CssBaseline />
            <Stack rowGap={spacing(1)}>
                <Grid container spacing={spacing(1)}>
                    <Grid item lg={1.5} md={3} sm={6} xs={12}>
                        <RecordList Title={'Total Users'} Records={'5'} />
                    </Grid>
                    <Grid item lg={1.5} md={3} sm={6} xs={12}>
                        <RecordList Title={'Total Inverters'} Records={'15'} />
                    </Grid>
                    <Grid item lg={1.5} md={3} sm={6} xs={12}>
                        <RecordList Title={'Live Inverters'} Records={'12'} />
                    </Grid>
                    <Grid item lg={1.5} md={3} sm={6} xs={12}>
                        <RecordList Title={'Offline Inverters'} Records={'3'} />
                    </Grid>
                    <Grid item lg={1.5} md={3} sm={6} xs={12}>
                        <RecordList Title={'Total Capacity'} Records={'2043.3 [KWp]'} />
                    </Grid>
                    <Grid item lg={1.5} md={3} sm={6} xs={12}>
                        <RecordList Title={'Total Generation'} Records={'1241806 [KWh]'} />
                    </Grid>
                    <Grid item lg={1.5} md={3} sm={6} xs={12}>
                        <RecordList Title={'Revenue Generated'} Records={'8035622 [₹]'} />
                    </Grid>
                    <Grid item lg={1.5} md={3} sm={6} xs={12}>
                        <RecordList Title={'CO2 Reduction'} Records={'741358.2 [Ton]'} />
                    </Grid>
                </Grid>

                <Card>
                    <CardActions>
                        {/* Important! Always set the container height explicitly */}
                        <Box style={{ height: '50vh', width: '100%' }}>
                            <GoogleMapReact
                                bootstrapURLKeys={{ key: "pGERgy7RyOs2gPxg-anATukFRZKXDETfcqGSFXoicHE" }}
                                defaultCenter={defaultProps.center}
                                defaultZoom={defaultProps.zoom}
                            >
                                <AnyReactComponent
                                    lat={30.381945}
                                    lng={75.546799}
                                    text="My Marker"
                                />
                            </GoogleMapReact>
                        </Box>
                    </CardActions>
                </Card>
                {/* </Container> */}

                <Grid container spacing={spacing(1)}>
                    <Grid item lg={3} md={3} sm={6} xs={12}>
                        <InverterList Title={'Total Users'} Value={6} />
                    </Grid>
                    <Grid item lg={3} md={3} sm={6} xs={12}>
                        <InverterList Title={'Total Inverters'} Value={14} />
                    </Grid>
                    <Grid item lg={3} md={3} sm={6} xs={12}>
                        <InverterList Title={'Live Inverters'} Value={9} />
                    </Grid>
                    <Grid item lg={3} md={3} sm={6} xs={12}>
                        <InverterList Title={'Offline Inverters'} Value={2} />
                    </Grid>
                </Grid>
            </Stack>
        </Box>
    )
}

export default GisLocation
