import * as React from 'react';
import PropTypes from 'prop-types';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import InboxIcon from '@mui/icons-material/MoveToInbox';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import List from '@mui/material/List';
import PersonIcon from '@mui/icons-material/Person';
import LoginIcon from '@mui/icons-material/Login';
import ListItemButton from '@mui/material/ListItemButton';
import { Link, useLocation } from 'react-router-dom';
import PowerIcon from '@mui/icons-material/Power';
import PieChartIcon from '@mui/icons-material/PieChart';
import MailIcon from '@mui/icons-material/Mail';
import MenuIcon from '@mui/icons-material/Menu';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import { Button, Card, CardActions, Collapse, Paper, Stack, useMediaQuery, useTheme } from '@mui/material';
import { CurtainsTwoTone, EnergySavingsLeaf, ExpandLess, ExpandMore, GasMeter, GraphicEq, GraphicEqOutlined, Grid3x3Outlined, GridGoldenratio, ImageOutlined, ListAlt, Map, NotificationsOutlined, Palette, PieChartSharp, PlaceTwoTone, Power, SmartButton, SolarPower, StarBorder, TypeSpecimen, YardOutlined } from '@mui/icons-material'
import MonitorIcon from '@mui/icons-material/Monitor';
import AnalyticsIcon from '@mui/icons-material/Analytics';
import SpaIcon from '@mui/icons-material/Spa';
import AllOutIcon from '@mui/icons-material/AllOut';
import ReportIcon from '@mui/icons-material/Report';
import AnchorIcon from '@mui/icons-material/Anchor';
import DetailsIcon from '@mui/icons-material/Details';
import { useState } from 'react';
import { useEffect } from 'react';
import Image from 'mui-image';
// import ColorPaletteDialog from '../palette/ColorPaletteDialog';
// import { LightTheme } from '../../Themes/MyTheme';
import { SuAuthenticationContext } from '../../../context/super_user_context/SuAuthenticationContext';
import ColorPaletteDialog from '../../palette/ColorPaletteDialog';
import { AuthenticationContext } from '../../../context/AuthenticationContext';
import { useContext } from 'react';
// import { useThemeContext } from '../../context/ThemeContext';

const drawerWidth = 240;

function SuperUserHeader({ window, children }) {
    const { logOut } = useContext(AuthenticationContext)

    const { palette, spacing, breakpoints, shape: { borderRadius } } = useTheme()
    const [colorPaletteDialogOpen, setColorPaletteDialogOpen] = useState(false);
    // const { updateThemeVariables } = useThemeContext();

    const handleColorPaletteDialogOpen = () => {
        setColorPaletteDialogOpen(true);
    };

    const handleColorPaletteSelect = (colors) => {
        // updateThemeVariables(updatedThemeVariables);
    };

    const handleColorPaletteDialogClose = () => {
        setColorPaletteDialogOpen(false);
    };

    const [currentTime, setCurrentTime] = useState(new Date());
    useEffect(() => {
        const intervalId = setInterval(() => {
            setCurrentTime(new Date());
        }, 1000);

        return () => clearInterval(intervalId);
    }, []);

    const hours = currentTime.getHours();
    const minutes = currentTime.getMinutes();
    const seconds = currentTime.getSeconds();
    const dates = currentTime.getDate()

    const year = currentTime.getFullYear();
    const month = currentTime.getMonth() + 1;
    const day = currentTime.getDate();

    const ampm = hours >= 12 ? 'PM' : 'AM';
    const formattedHours = hours % 12 || 12;

    const [open, setOpen] = useState(() => {
        const savedState = localStorage.getItem('drawerOpen');
        return savedState !== null ? JSON.parse(savedState) : true;
    });

    const [selectedIndex, setSelectedIndex] = useState(0);
    const location = useLocation()
    const laptopSize = useMediaQuery(breakpoints.down('xl'))
    const isLarge = useMediaQuery(breakpoints.up('xl'))
    const matchUpMd = useMediaQuery(breakpoints.up('lg'))

    useEffect(() => {
        if (laptopSize) {
            setOpen(false);
        } else {
            setOpen(true);
        }
    }, [laptopSize])

    useEffect(() => {
        localStorage.setItem('drawerOpen', JSON.stringify(open));
    }, [open]);

    const handleDrawerOpen = () => {
        setOpen(true);
    };

    const handleDrawerClose = () => {
        setOpen(false);
    };

    const [OpenMenu, setOpenMenu] = useState(true);

    const handleClick = () => {
        setOpenMenu(!OpenMenu);
    };

    const drawer = (
        <Box
            color={'background.default'}
            sx={{
                height: '100%',
                borderRight: 1,
                borderRightColor: 'lightgrey',
                bgcolor: 'primary.main',
            }}>
            <CssBaseline />
            <Stack sx={{ pt: spacing(1) }} alignItems={'center'} rowGap={spacing(1)}>
                <Card>
                    <CardActions>
                        <Image fit='contain' alt="Remy Sharp" src={require('../../../assets/img/logos/logo 1.svg').default} />
                    </CardActions>
                </Card>
                <Typography variant='body1'>eSolar Plant Monitoring System</Typography>
            </Stack>
            <List
                sx={{
                    '&& .Mui-selected, && .Mui-selected:hover': {
                        bgcolor: 'primary.light',
                        '&, & .MuiListItemIcon-root': {
                            color: 'background.default',
                        },
                    },
                    '& .MuiListItemButton-root:hover': {
                        bgcolor: 'primary.light',
                        '&, & .MuiListItemIcon-root': {
                            color: 'primary.main',
                        },
                        '&, & .ListItemText-root': {
                            color: 'background.default',
                        },
                    },
                }}

                component="nav" aria-label="main mailbox folders">

                <ListItemButton
                    onClick={() => isLarge ? null : setOpen(false)}
                    selected={location.pathname === '/'}
                    LinkComponent={Link}
                    to='.'
                >
                    <ListItemIcon color='inherit'>
                        <PieChartIcon fontSize='small' />
                    </ListItemIcon>
                    <ListItemText primary="Dashboard" />
                </ListItemButton>

                <ListItemButton
                    onClick={() => isLarge ? null : setOpen(false)}
                    selected={location.pathname === '/analytics'}
                    LinkComponent={Link}
                    to={'analytics'}
                >
                    <ListItemIcon>
                        <AnalyticsIcon fontSize='small' />
                    </ListItemIcon>
                    <ListItemText primary="Analytics" />
                </ListItemButton>

                <ListItemButton onClick={handleClick}>
                    <ListItemIcon>
                        <AnalyticsIcon fontSize='small' />
                    </ListItemIcon>
                    <ListItemText primary="Analytics2" />
                    {OpenMenu ? <ExpandLess /> : <ExpandMore />}
                </ListItemButton>
                <Collapse in={OpenMenu} timeout="auto" unmountOnExit>
                    <List component="div" sx={{ pl: spacing(2) }} disablePadding>
                        <ListItemButton
                            onClick={() => isLarge ? null : setOpen(false)}
                            selected={location.pathname === '/energy-generation'}
                            LinkComponent={Link}
                            to={'energy-generation'}
                        >
                            <ListItemIcon>
                                <EnergySavingsLeaf fontSize='small' />
                            </ListItemIcon>
                            <ListItemText primary="Energy Generation" />
                        </ListItemButton>

                        <ListItemButton
                            onClick={() => isLarge ? null : setOpen(false)}
                            selected={location.pathname === '/cuf'}
                            LinkComponent={Link}
                            to={'cuf'}
                        >
                            <ListItemIcon>
                                <CurtainsTwoTone fontSize='small' />
                            </ListItemIcon>
                            <ListItemText primary="CUF" />
                        </ListItemButton>
                        <ListItemButton
                            onClick={() => isLarge ? null : setOpen(false)}
                            selected={location.pathname === '/specific-yield'}
                            LinkComponent={Link}
                            to={'specific-yield'}
                        >
                            <ListItemIcon>
                                <YardOutlined fontSize='small' />
                            </ListItemIcon>
                            <ListItemText primary="Specific Yield" />
                        </ListItemButton>
                        <ListItemButton
                            onClick={() => isLarge ? null : setOpen(false)}
                            selected={location.pathname === '/specific-power'}
                            LinkComponent={Link}
                            to={'specific-power'}
                        >
                            <ListItemIcon>
                                <Power fontSize='small' />
                            </ListItemIcon>
                            <ListItemText primary="Specific Power" />
                        </ListItemButton>
                        <ListItemButton
                            onClick={() => isLarge ? null : setOpen(false)}
                            selected={location.pathname === '/peak-generation'}
                            LinkComponent={Link}
                            to={'peak-generation'}
                        >
                            <ListItemIcon>
                                <GridGoldenratio fontSize='small' />
                            </ListItemIcon>
                            <ListItemText primary="Peak Generation" />
                        </ListItemButton>
                    </List>
                </Collapse>

                <ListItemButton
                    onClick={() => isLarge ? null : setOpen(false)}
                    selected={location.pathname === '/analytics3'}
                    LinkComponent={Link}
                    to={'analytics3'}
                >
                    <ListItemIcon>
                        <AnalyticsIcon fontSize='small' />
                    </ListItemIcon>
                    <ListItemText primary="Analytics3" />
                </ListItemButton>

                <ListItemButton
                    onClick={() => isLarge ? null : setOpen(false)}
                    selected={location.pathname === '/gis-location'}
                    LinkComponent={Link}
                    to={'gis-location'}
                >
                    <ListItemIcon>
                        <Map fontSize='small' />
                    </ListItemIcon>
                    <ListItemText primary="GisLocation" />
                </ListItemButton>

                <ListItemButton
                    onClick={() => isLarge ? null : setOpen(false)}
                    selected={location.pathname === '/kwh-graph'}
                    LinkComponent={Link}
                    to={'kwh-graph'}
                >
                    <ListItemIcon>
                        <GraphicEqOutlined fontSize='small' />
                    </ListItemIcon>
                    <ListItemText primary="KwhGraph" />
                </ListItemButton>

                <ListItemButton
                    onClick={() => isLarge ? null : setOpen(false)}
                    selected={location.pathname === '/plant-mis'}
                    LinkComponent={Link}
                    to={'plant-mis'}
                >
                    <ListItemIcon>
                        <MonitorIcon fontSize='small' />
                    </ListItemIcon>
                    <ListItemText primary="Plant MIS" />
                </ListItemButton>

                <ListItemButton
                    onClick={() => isLarge ? null : setOpen(false)}
                    selected={location.pathname === '/plant-list'}
                    LinkComponent={Link}
                    to={'plant-list'}
                >
                    <ListItemIcon>
                        <ListAlt fontSize='small' />
                    </ListItemIcon>
                    <ListItemText primary="Plant List" />
                </ListItemButton>

                <ListItemButton
                    onClick={() => isLarge ? null : setOpen(false)}
                    selected={location.pathname === '/plant-image-list'}
                    LinkComponent={Link}
                    to={'plant-image-list'}
                >
                    <ListItemIcon>
                        <ImageOutlined fontSize='small' />
                    </ListItemIcon>
                    <ListItemText primary="Plant Image List" />
                </ListItemButton>

                <ListItemButton
                    onClick={() => logOut()}
                >
                    <ListItemIcon>
                        <LoginIcon fontSize='small' />
                    </ListItemIcon>
                    <ListItemText primary="Logout" />
                </ListItemButton>
            </List>
        </Box>
    );

    return (
        <Box sx={{ display: 'flex' }}>
            <CssBaseline />
            <AppBar
                color='primary'
                position="fixed"
                variant='elevation'
                elevation={2}
                sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}
            >
                <Toolbar sx={{}}>
                    <IconButton
                        aria-label="open drawer"
                        onClick={open ? handleDrawerClose : handleDrawerOpen}
                        edge="start"
                        color='inherit'
                    >
                        <MenuIcon />
                    </IconButton>
                    <Typography flexGrow={1} component="div" sx={{ pl: spacing(1), display: "flex", columnGap: spacing(1), alignItems: "center" }}>
                        <PieChartSharp fontSize='small' />
                        <Typography variant="h5"> Dashboard</Typography>
                    </Typography>
                    <Box direction={'row'} alignItems={'center'} justifyContent={'flex-end'}>
                        <Box flex={1} columnGap={spacing(1)} display={'flex'} alignItems={'center'}>
                            <Box flex={1} sx={{ display: { sm: 'none', xs: 'none', md: 'none', lg: 'flex', xl: 'flex' } }} columnGap={spacing(1)} alignItems={'center'}>
                                <Typography variant="body2" noWrap> {dates} / {month} / {year} </Typography>
                                <Stack p={spacing(1)} component={Card} direction={'row'} justifyContent={'space-between'} alignItems={'center'}>
                                    <Typography noWrap variant='body2' textAlign={'center'}>
                                        {formattedHours}:{minutes < 10 ? `0${minutes}` : minutes}:
                                        {seconds < 10 ? `0${seconds}` : seconds}
                                    </Typography>
                                    <Typography variant='body2'> {ampm}</Typography>
                                </Stack>
                            </Box>
                            <IconButton size='small' color='inherit'>
                                <NotificationsOutlined />
                            </IconButton>
                            <Paper>
                                <Image width={spacing(8)} wrapperStyle={{ padding: spacing(1) }} fit='contain' alt="Logics Power" src={require('../../../assets/img/logos/logo 1.svg').default} />
                            </Paper>
                            <IconButton color='inherit' onClick={handleColorPaletteDialogOpen}>
                                <Palette />
                            </IconButton>
                        </Box>
                    </Box>
                </Toolbar>
            </AppBar>
            <Drawer
                variant={matchUpMd ? 'persistent' : 'temporary'}
                open={open}
                onClose={handleDrawerClose}
                ModalProps={{ keepMounted: true }}
                sx={{
                    width: open ? drawerWidth : 0,
                    flexShrink: 0,
                    [`& .MuiDrawer-paper`]: { width: drawerWidth },
                }}
            >
                <Toolbar />
                {drawer}
            </Drawer>

            <Box
                component={'main'}
                sx={{
                    flexGrow: 1,
                    backgroundColor: palette.background.paper,
                    p: spacing(1), width: `calc(100% - ${drawerWidth}px)`
                }}>
                <Toolbar />
                {children}
            </Box>
            <ColorPaletteDialog
                open={colorPaletteDialogOpen}
                onClose={handleColorPaletteDialogClose}
                onSelectColors={handleColorPaletteSelect}
            />
        </Box>
    );
}

export default SuperUserHeader;
