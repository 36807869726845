import { AccountCircle, Person, SolarPower } from '@mui/icons-material'
import { Box, Button, Card, CardActions, CardHeader, Container, CssBaseline, Divider, FormControl, Grid, Icon, IconButton, InputBase, InputLabel, MenuItem, Paper, Select, Stack, Typography, useTheme } from '@mui/material'
import React, { useContext, useEffect, useState } from 'react'
import RecordList from '../../../components/SuperUser/dashboard/RecordList'
import Image from 'mui-image'
import GoogleMapReact from 'google-map-react';
import { Loader } from '@googlemaps/js-api-loader'
import InverterList from '../../../components/SuperUser/InverterList'
import { Table } from 'react-bootstrap'
import SuPagination from '../../../components/SuperUser/general/SuPagination'
import { PlantListContext, PlantListDataContext } from '../../../context/super_user_context/PlantListContext'
import { InverterContext } from '../../../context/InverterContext'
import { useLocation, useNavigate } from 'react-router-dom'
import { AuthenticationContext } from '../../../context/AuthenticationContext'
import AxiosApi from '../../../api/AxiosApi'

function PlantList() {
    const Navigate = useNavigate()
    const { logOut, userLogin, userLoginNewWindow, setIsLogged } = useContext(AuthenticationContext)
    const { palette, spacing, shape: { borderRadius } } = useTheme()
    const [SelectedType, setSelectedType] = React.useState('');
    const { PLANT_LIST_DATA, PLANT_LIST_DATA_LOADING, getPlantListData } = useContext(PlantListContext)
    const location = useLocation()

    useEffect(() => {
        getPlantListData()
    }, [])



    // console.log("plantlist Datas", PLANT_LIST_DATA)

    const AnyReactComponent = ({ text }) => <div>{text}</div>;

    const position = [51.505, -0.09]
    const defaultProps = {
        center: {
            lat: 10.99835602,
            lng: 77.01502627
        },
        zoom: 11
    };
    const handleChangeSelectedType = (event) => {
        setSelectedType(event.target.value);
    };

    const table_head = ['SN', 'user_name', 'address', 'capacity', 'city', 'loc_code', 'rate', 'co2', 'nom', 'placeId', 'Latitude', 'Longitude', 'ID'];

    const table_data = []
    console.log("plant list data", PLANT_LIST_DATA)

    PLANT_LIST_DATA.map((ele, index) => {

        var newObj = {
            "SN": index + 1,
            "user_name": ele.user_name,
            "address": ele.address,
            "capacity": ele.capacity,
            "city": ele.city,
            "loc_code": ele.loc_code,
            "rate": ele.rate,
            "co2": ele.co2,
            "nom": ele.nom,
            "placeId": ele.placeId,
            "Latitude": ele.Latitude,
            "Longitude": ele.Longitude,
            "ID": ele.ID
        }
        table_data.push(newObj)

    })




    // function handleRowClick(item) {


    //     // localStorage.clear();
    //     var new_password = null;
    //     PLANT_LIST_DATA.map((e) => {
    //         if (e.ID == item.ID) {
    //             localStorage.setItem('LoginPassword', e.password)
    //             new_password = e.password
    //         }
    //     })

    //     localStorage.setItem("LoginID", item.ID)
    //     localStorage.setItem("UserType", "User")
    //     localStorage.setItem("WEB_KEY", "WEBXYZ321")
    //     localStorage.setItem("LoginPassword", new_password)
    //     console.log('data to be passed', item.ID, new_password)


    //     userLoginNewWindow(item.ID, new_password, "WEBXYZ321", "User")


    //     window.open("/login?direct=true", '_blank');




    // }


    function handleRowClick(item) {
        if (!localStorage.getItem('LoginID') || localStorage.getItem('LoginID') !== item.ID) {
            var new_password = null;
            PLANT_LIST_DATA.map((e) => {
                if (e.ID == item.ID) {
                    localStorage.setItem('LoginPassword', e.password)
                    new_password = e.password
                }
            });

            localStorage.setItem("LoginID", item.ID);
            localStorage.setItem("WEB_KEY", "WEBXYZ321");
            localStorage.setItem("LoginPassword", new_password);

            console.log('data to be passed', item.ID, new_password);

            // userLogin(item.ID, new_password, "WEBXYZ321", "User", 'next');

            // Open the new tab and get a reference to it
            var newTab = window.open("/login?direct=true", '_blank');

            // Ensure the new tab has loaded before setting sessionStorage
            newTab.onload = function () {
                newTab.sessionStorage.setItem("UserType", "User");
                localStorage.setItem('LoginID', item.ID)
                localStorage.setItem('LoginPassword', new_password)
                let bodyContent = `LOGIN_NAME=${item.ID}&PW=${new_password}&APP_KEY=${"WEBXYZ321"}`;
                let headersList = {
                    "Accept": "/",
                    "Content-Type": "application/x-www-form-urlencoded"
                }
                AxiosApi.post('login/LoginWEB', bodyContent, headersList)
                    .then((res) => {

                        sessionStorage.setItem('LoggedInUserInfo', JSON.stringify(res?.data))
                        localStorage.setItem('LCODE', res?.data?.Loc_Code)


                    }).catch((er) => {
                        sessionStorage.removeItem('UserType')
                        alert('Invalid Credentials!')


                    });

                // userLogin(item.ID, new_password, "WEBXYZ321", "User")
                newTab.location.reload();
                newTab.location.href = "/";
                // You can set more items in session storage here if needed
            };

        } else {
            console.log('LocalStorage data already set for this user.');
        }
    }



    // function handleRowClick(item) {
    //     if (!localStorage.getItem('LoginID') || localStorage.getItem('LoginID') !== item.ID) {
    //         var new_password = null;
    //         PLANT_LIST_DATA.map((e) => {
    //             if (e.ID == item.ID) {
    //                 localStorage.setItem('LoginPassword', e.password);
    //                 new_password = e.password;
    //             }
    //         });

    //         localStorage.setItem("LoginID", item.ID);
    //         localStorage.setItem("WEB_KEY", "WEBXYZ321");
    //         localStorage.setItem("LoginPassword", new_password);

    //         console.log('data to be passed', item.ID, new_password);

    //         // Open the new window with parameters
    //         var newWindow = window.open(`/login?direct=true&LoginID=${item.ID}&LoginPassword=${new_password}&WEB_KEY=WEBXYZ321`, '_blank');
    //         // window.location.reload();
    //         // Since the sessionStorage must be set in the new window, you can't directly set it here
    //         newWindow.onload = function () {
    //             newWindow.sessionStorage.setItem("UserType", "User");
    //             window.location.reload();
    //             userLogin(item.ID, new_password, "WEBXYZ321", "User");
    //         };

    //         // Assuming userLogin needs to be called here (in the original window)
    //         // userLogin(item.ID, new_password, "WEBXYZ321", "User");
    //     } else {
    //         console.log('LocalStorage data already set for this user.');
    //     }
    // }







    const [currentPage, setCurrentPage] = useState(1);
    const [recordsPerPage] = useState(10);

    const indexOfLastRecord = currentPage * recordsPerPage;
    const indexOfFirstRecord = indexOfLastRecord - recordsPerPage;
    const currentRecords = table_data.slice(indexOfFirstRecord, indexOfLastRecord);
    const nPages = Math.ceil(table_data.length / recordsPerPage)
    return (
        <Box>
            <CssBaseline />
            <Stack rowGap={spacing(1)}>
                <Grid container spacing={spacing(1)}>
                    <Grid item lg={3} md={3} sm={6} xs={12}>
                        <InverterList Title={'Total Users'} Value={6} />
                    </Grid>
                    <Grid item lg={3} md={3} sm={6} xs={12}>
                        <InverterList Title={'Total Inverters'} Value={14} />
                    </Grid>
                    <Grid item lg={3} md={3} sm={6} xs={12}>
                        <InverterList Title={'Live Inverters'} Value={9} />
                    </Grid>
                    <Grid item lg={3} md={3} sm={6} xs={12}>
                        <InverterList Title={'Offline Inverters'} Value={2} />
                    </Grid>
                </Grid>
                <Card>
                    <CardActions>
                        <Stack rowGap={spacing(1)} flex={1}>
                            {/* <CardHeader subheader='Plant List' /> */}
                            <Typography py={spacing(1)} variant='body2'>Plant List</Typography>
                            {/* <Divider variant='fullWidth' /> */}
                            <Stack direction={{ xs: 'column-reverse', sm: 'row' }} justifyContent={'space-between'} rowGap={1} >
                                <Stack direction={'row'} alignItems={'center'} columnGap={spacing(1)}>
                                    <Typography variant='body2'>Show</Typography>
                                    <Box sx={{ minWidth: 150 }}>
                                        <FormControl fullWidth>
                                            <InputLabel id="demo-simple-select-label">Select Type</InputLabel>
                                            <Select
                                                variant='outlined'
                                                labelId="demo-simple-select-label"
                                                id="demo-simple-select"
                                                value={SelectedType}
                                                label='type'
                                                onChange={handleChangeSelectedType}
                                                size='small'
                                            >
                                                <MenuItem value={10}>Energy Generation</MenuItem>
                                                <MenuItem value={20}>CUF</MenuItem>
                                                <MenuItem value={30}>Specific Yield</MenuItem>
                                            </Select>
                                        </FormControl>
                                    </Box>
                                    <Typography variant='body2'>Enteries</Typography>
                                </Stack>
                                <Stack direction={'row'} alignItems={'center'} columnGap={spacing(1)}>
                                    <Stack px={spacing(1)} columnGap={spacing(1)} direction={'row'} alignItems={'center'}>
                                        <Button variant='outlined' size='medium' sx={{}}>Search</Button>
                                        <InputBase placeholder='search users' style={{ border: `1px solid ${palette.primary.light}`, padding: spacing(0.8), borderRadius: borderRadius }} />
                                    </Stack>
                                </Stack>
                            </Stack>
                        </Stack>
                    </CardActions>
                </Card>
                <Card>
                    <CardActions sx={{ flex: 1 }}>
                        <Stack flex={1} rowGap={spacing(1)}>
                            <Button variant='outlined' sx={{ alignSelf: 'start' }}>EXCEL</Button>
                            {
                                currentRecords?.length <= 0 ? (
                                    <Typography variant='body2' color={'error.main'} textAlign={'center'}>Data Not Found</Typography>
                                ) : (
                                    <Box sx={{ flex: 1 }}>
                                        <div className='table-responsive'>
                                            <Table style={{}} id='table-to-pdf' striped bordered hover responsive>
                                                <thead>
                                                    <tr style={{ textAlign: 'center' }}>
                                                        {Object.keys(currentRecords[0]).map((key, index) => (
                                                            <th key={index} style={{ padding: 15, backgroundColor: "#FDF8E4", color: palette.primary.main }}>{key}</th>
                                                        ))}
                                                    </tr>
                                                </thead>

                                                <tbody>
                                                    {
                                                        currentRecords?.map((item, index) => {
                                                            // console.log('data', item)
                                                            return (
                                                                <tr key={index} onClick={() => handleRowClick(item)} style={{ cursor: 'pointer' }}>
                                                                    {table_head?.map(key =>
                                                                        <td key={key} style={{ textAlign: 'center', padding: spacing(1), whiteSpace: 'nowrap' }} align='center'>{item[key]}</td>
                                                                    )}
                                                                </tr>
                                                            )
                                                        })
                                                    }
                                                </tbody>
                                            </Table>
                                        </div>

                                        <Box
                                            sx={{ display: 'flex', justifyContent: 'end' }}
                                        >
                                            <SuPagination
                                                nPages={nPages}
                                                currentPage={currentPage}
                                                setCurrentPage={setCurrentPage}
                                            />
                                        </Box>

                                    </Box>

                                )
                            }
                        </Stack>

                    </CardActions>
                </Card>


            </Stack>
        </Box>
    )
}

export default PlantList
