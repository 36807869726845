import { colors, useTheme } from '@mui/material'
import React, { useContext, useRef } from 'react'

const SuPagination = ({ nPages, currentPage, setCurrentPage }) => {
    // const { GoToBottom, setGoToBottom } = useContext(WmcContext)
    const { palette, typography } = useTheme()
    const messageRef = useRef()
    const pageNumbers = [...Array(nPages + 1).keys()].slice(1)
    const nextPage = () => {
        if (currentPage !== nPages) setCurrentPage(currentPage + 1)
        // scrollToBottom()
    }
    const prevPage = () => {
        if (currentPage !== 1) setCurrentPage(currentPage - 1)
        // scrollToBottom()
    }
    function scrollToBottom() {
        // setGoToBottom(!GoToBottom)
    }

    const styles = {
        PageLink: {
            fontSize: typography.body1.fontSize,
            paddingInline: '5px',
            color: palette.primary.main,
            marginBlock: '5px',
            lineHeight: '10px'
        }
    }

    return (
        <>
            <div ref={messageRef}></div>
            <nav>
                <ul className='p-0 m-0 pagination justify-content-center'>
                    <li className=" page-item">
                        <a className="page-link"
                            onClick={prevPage}
                            href='#'
                            style={styles.PageLink}
                        >
                            Previous
                        </a>
                    </li>
                    {pageNumbers.map(pgNumber => (
                        <li key={pgNumber}
                            className={`page-item ${currentPage == pgNumber ? 'active' : ''} `} >
                            <a onClick={() => {
                                setCurrentPage(pgNumber)
                                scrollToBottom()
                            }}
                                className='page-link'
                                href='#'
                                style={{
                                    fontSize: typography.body1.fontSize,
                                    paddingInline: '5px',
                                    // color: palette.primary.light,
                                    marginBlock: '5px',
                                    // backgroundColor: palette.primary.main,
                                    lineHeight: '10px'
                                }}
                            >
                                {pgNumber}
                            </a>
                        </li>
                    ))}
                    <li className="page-item">
                        <a className="page-link"
                            onClick={nextPage}
                            style={styles.PageLink}
                            href='#'>
                            Next
                        </a>
                    </li>
                </ul>
            </nav>
        </>

    )
}

export default SuPagination