import { AccountCircle, Refresh, RefreshOutlined, RefreshRounded } from '@mui/icons-material'
import { Box, Button, Card, CardActions, CardHeader, CssBaseline, FormControl, FormControlLabel, FormLabel, Grid, Icon, IconButton, InputBase, InputLabel, MenuItem, Radio, RadioGroup, Select, Stack, Typography, useTheme } from '@mui/material'
import React from 'react'
import RecordList from '../../../components/SuperUser/dashboard/RecordList'
import Image from 'mui-image'
import UsersIDList from '../../../components/SuperUser/dashboard/UsersIDList'

function Analytics() {
    const { palette, spacing, shape: { borderRadius } } = useTheme()
    const [SelectedType, setSelectedType] = React.useState('');
    const handleChangeSelectedType = (event) => {
        setSelectedType(event.target.value);
    };

    const [UsersValue, setValue] = React.useState('gt');
    const handleChangeUsersValue = (event) => {
        setValue(event.target.value);
    };

    const [InvValue, setValueInv] = React.useState('inv2');
    const handleChangeInvValue = (event) => {
        setValueInv(event.target.value);
    };

    return (
        <Box>
            <CssBaseline />
            <Stack rowGap={spacing(1)}>
                <Grid container spacing={spacing(1)}>
                    <Grid item lg={1.5} md={3} sm={6} xs={12}>
                        <RecordList Title={'Total Users'} Records={'5'} />
                    </Grid>
                    <Grid item lg={1.5} md={3} sm={6} xs={12}>
                        <RecordList Title={'Total Inverters'} Records={'15'} />
                    </Grid>
                    <Grid item lg={1.5} md={3} sm={6} xs={12}>
                        <RecordList Title={'Live Inverters'} Records={'12'} />
                    </Grid>
                    <Grid item lg={1.5} md={3} sm={6} xs={12}>
                        <RecordList Title={'Offline Inverters'} Records={'3'} />
                    </Grid>
                    <Grid item lg={1.5} md={3} sm={6} xs={12}>
                        <RecordList Title={'Total Capacity'} Records={'2043.3 [KWp]'} />
                    </Grid>
                    <Grid item lg={1.5} md={3} sm={6} xs={12}>
                        <RecordList Title={'Total Generation'} Records={'1241806 [KWh]'} />
                    </Grid>
                    <Grid item lg={1.5} md={3} sm={6} xs={12}>
                        <RecordList Title={'Revenue Generated'} Records={'8035622 [₹]'} />
                    </Grid>
                    <Grid item lg={1.5} md={3} sm={6} xs={12}>
                        <RecordList Title={'CO2 Reduction'} Records={'741358.2 [Ton]'} />
                    </Grid>
                </Grid>
                {/* <Stack component={Card} px={spacing(1)} direction={'row'} alignItems={'center'}>
                    <InputBase placeholder='search users' style={{ border: `1px solid ${palette.primary.light}`, paddingInline: spacing(1), borderRadius: borderRadius }} />
                    <Button variant='text'>Search</Button>
                </Stack> */}

                <Typography variant='body2'>Analytics Report</Typography>
                <Grid container component={Card} padding={spacing(1)} spacing={spacing(1)}>
                    <Grid item sm={6}>
                        <Grid container spacing={spacing(1)}>
                            <Grid item lg={4} xs={12} sm={6} md={6}>
                                <Card>
                                    <CardHeader subheader={<Typography variant='body2'>Users</Typography>} sx={{ padding: spacing(2), color: "white", bgcolor: 'primary.light' }} />
                                    <CardActions>
                                        <Stack>
                                            <FormControl>
                                                <RadioGroup
                                                    aria-labelledby="demo-controlled-radio-buttons-group"
                                                    name="controlled-radio-buttons-group"
                                                    value={UsersValue}
                                                    onChange={handleChangeUsersValue}
                                                >
                                                    <FormControlLabel value="gt" control={<Radio size='small' />} label="Geeta Threads Limited [885 kWp kWp]" />
                                                    <FormControlLabel value="bt" control={<Radio size='small' />} label="Bajaj Textile Mill [100 kWp kWp]" />
                                                    <FormControlLabel value="go" control={<Radio size='small' />} label="Gulati Oil India Pvt Ltd [500.31 kWp kWp]" />
                                                    <FormControlLabel value="kg" control={<Radio size='small' />} label="Kapil Garments [59 kWp kWp]" />
                                                    <FormControlLabel value="af" control={<Radio size='small' />} label="ATUL FASTENERS [600 kWp kWp]" />
                                                </RadioGroup>
                                            </FormControl>
                                        </Stack>
                                    </CardActions>
                                </Card>
                            </Grid>
                            <Grid item lg={4} xs={12} sm={6} md={6}>
                                <Card>
                                    <CardHeader subheader={<Typography variant='body2'>Inverters</Typography>} sx={{ padding: spacing(2), color: "white", bgcolor: 'primary.light' }} />
                                    <CardActions>
                                        <Stack>
                                            <FormControl>
                                                <RadioGroup
                                                    aria-labelledby="demo-controlled-radio-buttons-group"
                                                    name="controlled-radio-buttons-group"
                                                    value={InvValue}
                                                    onChange={handleChangeInvValue}
                                                >
                                                    <FormControlLabel value="inv1" control={<Radio size='small' />} label="INV-1 [885 kWp kWp]" />
                                                    <FormControlLabel value="inv2" control={<Radio size='small' />} label="INV-2 [100 kWp kWp]" />
                                                    <FormControlLabel value="inv3" control={<Radio size='small' />} label="INV-3 [500.31 kWp kWp]" />

                                                </RadioGroup>
                                            </FormControl>
                                        </Stack>
                                    </CardActions>
                                </Card>
                            </Grid>
                            <Grid item lg={4} xs={12} sm={6} md={6}>
                                <Card>
                                    <CardHeader subheader={<Typography variant='body2'>Compare Inverters</Typography>} sx={{ padding: spacing(2), color: "white", bgcolor: 'primary.light' }} />
                                    <CardActions>
                                        <Stack rowGap={spacing(1)}>
                                            <Stack direction={'row'} alignItems={'center'}>
                                                <Typography>INV-2 [287kWp] [Geeta Threads Limited]</Typography>
                                                <Button size='medium' variant='outlined'>Remove</Button>
                                            </Stack>
                                            <Stack direction={'row'} alignItems={'center'}>
                                                <Typography>INV-2 [287kWp] [Geeta Threads Limited]</Typography>
                                                <Button variant='outlined'>Remove</Button>
                                            </Stack>
                                            <Stack direction={'row'} alignItems={'center'}>
                                                <Typography>INV-2 [287kWp] [Geeta Threads Limited]</Typography>
                                                <Button variant='outlined'>Remove</Button>
                                            </Stack>
                                        </Stack>

                                    </CardActions>
                                </Card>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                <Stack direction={'row'} component={Card} p={spacing(2)} alignItems={'center'} columnGap={spacing(2)}>
                    <Typography variant='body2'>Select Type</Typography>
                    <Box sx={{ minWidth: 120 }}>
                        <FormControl fullWidth>
                            <InputLabel id="demo-simple-select-label">Select Type</InputLabel>
                            <Select
                                variant='outlined'
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={SelectedType}
                                label='type'
                                onChange={handleChangeSelectedType}
                                size='small'
                            >
                                <MenuItem value={10}>Energy Generation</MenuItem>
                                <MenuItem value={20}>CUF</MenuItem>
                                <MenuItem value={30}>Specific Yield</MenuItem>
                            </Select>
                        </FormControl>
                    </Box>

                </Stack>
                <Stack direction={'row'} columnGap={spacing(1)}>
                    <Stack direction={'row'} alignItems={'center'} columnGap={spacing(1)}>
                        <Typography>From Date</Typography>
                        <input type='date' className='searchField' />
                    </Stack>
                    <Stack direction={'row'} alignItems={'center'} columnGap={spacing(1)}>
                        <Typography>To Date</Typography>
                        <input type='date' className='searchField' />
                    </Stack>
                    <IconButton size='small'>
                        <RefreshRounded fontSize='small' />
                    </IconButton>
                </Stack>
            </Stack>
        </Box>
    )
}

export default Analytics
