

import React, { createContext, useEffect, useState } from 'react'
import AxiosApi, { PASS_KEY, WEB_KEY } from '../api/AxiosApi';
import { ToastContainer, toast } from 'react-toastify';
import axios from 'axios';

export const InverterContext = createContext()
export function InverterProvider({ children }) {
    const [InvChartLoading, setInvChartLoading] = useState(false)
    const [InverterChartData, setInverterChartData] = useState([])
    const [HYBRD_DATA, setHYBRD_DATA] = useState([])
    const [HYBRD_DATA_POWER_Loading, setHYBRD_DATA_POWER_Loading] = useState(false)

    const [HYBRD_DATA_POWER, setHYBRD_DATA_POWER] = useState([])
    const [HYBRD_DATA_INFO, setHYBRD_DATA_INFO] = useState([])
    const [HYBRD_MORE_DATA, setHYBRD_MORE_DATA] = useState([])
    const [HYBRD_LOADING, setHYBRD_LOADING] = useState(false)
    const [EnergyKwhOverviewData, setEnergyKwhOverviewData] = useState([])
    const [POST_KWH_HYBRID_DATA, setPOST_KWH_HYBRID_DATA] = useState([])
    const [POST_KWH_LOADING, setPOST_KWH_LOADING] = useState(false)
    const [POST_KWH_HYBRID_DATA_DASHBOARD, setPOST_KWH_HYBRID_DATA_DASHBOARD] = useState([])

    const [HYBRD_CONTROLS_DATA, setHYBRD_CONTROLS_DATA] = useState(null)
    const [HYBRD_CONTROLS_LOADING, setHYBRD_CONTROLS_LOADING] = useState(false)

    const [HYBRD_SET_VALUES_DATA, setHYBRD_SET_VALUES_DATA] = useState(null)
    const [HYBRD_SETVALUE_LOADING, setHYBRD_SETVALUE_LOADING] = useState(false)

    const [HYBRD_CONTROLS_LOG_DATA, setHYBRD_CONTROLS_LOG_DATA] = useState([])
    const [HYBRD_CONTROLS_LOG_DATA_LOADING, setHYBRD_CONTROLS_LOG_DATA_LOADING] = useState(false)

    const [SET_VALUE_SAVE_BUTTON, setSET_VALUE_SAVE_BUTTON] = useState([])
    const [SET_VALUE_SAVE_BUTTON_LOADING, setSET_VALUE_SAVE_BUTTON_LOADING] = useState(false)

    const [CONTROL_VALUE_SAVE_SWITCH, setCONTROL_VALUE_SAVE_SWITCH] = useState([])
    const [SET_CONTROL_VALUE_SWITCH_LOADING, setSET_CONTROL_VALUE_SWITCH_LOADING] = useState(false)


    const [fdGridDG, setGridDG] = useState(false)
    const [fdDGRun, setDGRun] = useState(false)
    const [fdDGStart, setDGStart] = useState(false)
    const [fdAbsorption, setAbsorption] = useState(false)
    const [fdBulk, setBulk] = useState(false)
    const [fdFloat, setFloat] = useState(false)
    const [fdLowCut, setLowCut] = useState(false)
    const [fdACChargerstart, setsACChargerstart] = useState(false)
    const [fdACChargerstop, setACChargerstop] = useState(false)
    const [fdMinload, setMinload] = useState(false)
    const [fdExportpower, setExportpower] = useState(false)
    const [fdBatteryAh, setBatteryAh] = useState(false)
    const [fdBatterycharging, setBatterycharging] = useState(false)
    const [fdMPPTMode, setMPPTMode] = useState(false)
    const [fdINVMode, setINVMode] = useState(false)
    const [fdKey, setKey] = useState(false)
    const [fdBatteryLow, setBatteryLow] = useState(false)
    const [fdExportstart, setExportstart] = useState(false)
    const [fdExportstop, setExportstop] = useState(false)


    const [EXCEL_FLAG_VALUE, setEXCEL_FLAG_VALUE] = useState(true)







    function getInverterChartData(LCODE, DT1, DT2) {
        setInvChartLoading(true)
        let headersList = {
            "Accept": "/",
            "Content-Type": "application/x-www-form-urlencoded"
        }
        let bodyContent = `LCODE=${LCODE}&DT1=${DT1}&DT2=${DT2}`;
        AxiosApi.post('POWER/post_KWH_IRR', bodyContent, headersList)
            .then((res) => {
                setInverterChartData(res?.data?.KWH_MIS)
                // console.log('InvTableSuccess', res?.data?.KWH_MIS)
                setInvChartLoading(false)
            }).catch((er) => {
                // alert('record not found')
                console.log('fetInvErrorChart', er)
                setInvChartLoading(false)
            });

    }

    function getGridLoadData(LCODE) {
        let headersList = {
            "Accept": "/",
            "Content-Type": "application/x-www-form-urlencoded"
        }
        // let bodyContent = `LCODE=${LCODE}`;
        AxiosApi.get(`Power/GET_TOT_LIVE_KW_NEW?LCODE=${LCODE}`, headersList)
            .then((res) => {
                // setInverterChartData(res?.data)
                // console.log('SuccessGridLoad', res.data)
                // setInvChartLoading(false)
            }).catch((er) => {
                console.log('fetGridLoadError', er)
                // setInvChartLoading(false)
            });

    }

    function getHYBRD(LOGIN_ID, LOC_CODE, PASS_KEY, WEB_KEY, TYP, IROW, DT1, DT2) {
        // console.log('getHYBRD.............')
        setHYBRD_LOADING(true)
        let headersList = {
            "Accept": "/",
            "Content-Type": "application/x-www-form-urlencoded"
        }
        AxiosApi.get(`CMN_03/Get_HYBRD?LOGIN_ID=${LOGIN_ID}&LOC_CODE=${LOC_CODE}&PASS_KEY=${PASS_KEY}&WEB_KEY=${WEB_KEY}&TYP=${TYP}&IROW=${IROW}&DT1=${DT1}&DT2=${DT2}`, headersList)
            .then((res) => {
                setHYBRD_DATA(res?.data?.HYBRD[0])
                // console.log('DATAAaaaaa', res?.data)
                setHYBRD_LOADING(false)
            }).catch((e) => {
                console.log('getHYBRD_ERROR', e)
                setHYBRD_LOADING(false)
            }).finally(() => {
                setHYBRD_LOADING(false)

            })
    }

    function getHYBRD_Power(LOGIN_ID, LOC_CODE, PASS_KEY, WEB_KEY, TYP, IROW, DT1, DT2) {
        // console.log('getHYBRDPOWER_PARMAS', { LOGIN_ID, LOC_CODE, PASS_KEY, WEB_KEY, TYP, IROW, DT1, DT2 })
        setHYBRD_DATA_POWER_Loading(true)

        let headersList = {
            "Accept": "/",
            "Content-Type": "application/x-www-form-urlencoded"
        }
        AxiosApi.get(`CMN_03/Get_HYBRD?LOGIN_ID=${LOGIN_ID}&LOC_CODE=${LOC_CODE}&PASS_KEY=${PASS_KEY}&WEB_KEY=${WEB_KEY}&TYP=${TYP}&IROW=${IROW}&DT1=${DT1}&DT2=${DT2}`, headersList)
            .then((res) => {
                setHYBRD_DATA_POWER(res?.data?.HYBRD)
                // console.log('HYBRD_POWER_DATA', res?.data)
                setHYBRD_DATA_POWER_Loading(false)

            }).catch((e) => {
                console.log('getHYBRD_ERROR', e)
                setHYBRD_DATA_POWER_Loading(false)

            }).finally(() => {

            })
    }


    function getHYBRD_Info(LOGIN_ID, LOC_CODE, PASS_KEY, WEB_KEY, TYP, IROW, DT1, DT2) {
        // console.log('getHYBRDPOWER_INFo', { LOGIN_ID, LOC_CODE, PASS_KEY, WEB_KEY, TYP, IROW, DT1, DT2 })
        let headersList = {
            "Accept": "/",
            "Content-Type": "application/x-www-form-urlencoded"
        }
        AxiosApi.get(`CMN_03/Get_HYBRD?LOGIN_ID=${LOGIN_ID}&LOC_CODE=${LOC_CODE}&PASS_KEY=${PASS_KEY}&WEB_KEY=${WEB_KEY}&TYP=${TYP}&IROW=${IROW}&DT1=${DT1}&DT2=${DT2}`, headersList)
            .then((res) => {
                setHYBRD_DATA_INFO(res?.data?.HYBRD[0])
                // console.log('HYBRD_POWER_INFO', res?.data)
            }).catch((e) => {
                console.log('getHYBRD_ERROR', e)
            }).finally(() => {

            })
    }

    function getHYBRDwithZero(LOGIN_ID, LOC_CODE, PASS_KEY, WEB_KEY, TYP, IROW, DT1, DT2) {
        // console.log('getHYBRDwithZero.................')
        // setHYBRD_LOADING(true)
        // console.log('params', { LOGIN_ID, LOC_CODE, PASS_KEY, WEB_KEY, TYP, IROW, DT1, DT2 })
        let headersList = {
            "Accept": "/",
            "Content-Type": "application/x-www-form-urlencoded"
        }
        AxiosApi.get(`CMN_03/Get_HYBRD?LOGIN_ID=${LOGIN_ID}&LOC_CODE=${LOC_CODE}&PASS_KEY=${PASS_KEY}&WEB_KEY=${WEB_KEY}&TYP=${TYP}&IROW=${IROW}&DT1=${DT1}&DT2=${DT2}`, headersList)
            .then((res) => {
                setHYBRD_MORE_DATA(res?.data?.HYBRD)
                // console.log('DATAwith0', res?.data)
                // setHYBRD_LOADING(false)
            }).catch((e) => {
                console.log('getHYBRD_ERROR', e)
                // setHYBRD_LOADING(false)
            }).finally(() => {
                // setHYBRD_LOADING(false)

            })
    }

    function getPostKwhHybrid(LOGIN_ID, LOC_CODE, PASS_KEY, WEB_KEY, TYP, DT1, DT2) {
        // console.log('getPostKwhHybridParams', { LOGIN_ID, LOC_CODE, PASS_KEY, WEB_KEY, TYP, DT1, DT2 })
        setPOST_KWH_LOADING(true)
        let headersList = {
            "Accept": "/",
            "Content-Type": "application/x-www-form-urlencoded"
        }
        let bodyContent = `LOGIN_ID=${LOGIN_ID}&LCODE=${LOC_CODE}&PASS_KEY=${PASS_KEY}&WEB_KEY=${WEB_KEY}&TYP=${TYP}&DT1=${DT1}&DT2=${DT2}`
        AxiosApi.post(`https://log.solaramr.com/API/CMN_03/POST_KWH_HYBRID`, bodyContent, headersList)
            .then((res) => {
                setPOST_KWH_HYBRID_DATA(res?.data?.KWH_HYBRID)
                // console.log('api', res?.data)
                // setPOST_KWH_LOADING(false)
            }).catch((e) => {
                console.log('getHYBRD_ERROR', e)
                // setPOST_KWH_LOADING(false)
            }).finally(() => {
                // setPOST_KWH_LOADING(false)

            })
    }

    function getPostKwhHybridEnergyOverview(LOGIN_ID, LOC_CODE, PASS_KEY, WEB_KEY, TYP, DT1, DT2) {
        // console.log('getPostKwhHybridEnergyOverview', { LOGIN_ID, LOC_CODE, PASS_KEY, WEB_KEY, TYP, DT1, DT2 })
        // setPOST_KWH_LOADING(true)
        let headersList = {
            "Accept": "/",
            "Content-Type": "application/x-www-form-urlencoded"
        }
        let bodyContent = `LOGIN_ID=${LOGIN_ID}&LCODE=${LOC_CODE}&PASS_KEY=${PASS_KEY}&WEB_KEY=${WEB_KEY}&TYP=${TYP}&DT1=${DT1}&DT2=${DT2}`
        AxiosApi.post(`https://log.solaramr.com/API/CMN_03/POST_KWH_HYBRID`, bodyContent, headersList)
            .then((res) => {
                setEnergyKwhOverviewData(res?.data?.KWH_HYBRID)
                // console.log('api', res?.data)
                // setPOST_KWH_LOADING(false)
            }).catch((e) => {
                console.log('getHYBRD_ERROR', e)
                // setPOST_KWH_LOADING(false)
            }).finally(() => {
                // setPOST_KWH_LOADING(false)

            })
    }

    function getPostKwhHybridForDashboard(LOGIN_ID, LOC_CODE, PASS_KEY, WEB_KEY, TYP, DT1, DT2) {
        // console.log('getPostKwhHybridParams', { LOGIN_ID, LOC_CODE, PASS_KEY, WEB_KEY, TYP, DT1, DT2 })
        // setPOST_KWH_LOADING(true)
        let headersList = {
            "Accept": "/",
            "Content-Type": "application/x-www-form-urlencoded"
        }
        let bodyContent = `LOGIN_ID=${LOGIN_ID}&LCODE=${LOC_CODE}&PASS_KEY=${PASS_KEY}&WEB_KEY=${WEB_KEY}&TYP=${TYP}&DT1=${DT1}&DT2=${DT2}`
        AxiosApi.post(`https://log.solaramr.com/API/CMN_03/POST_KWH_HYBRID`, bodyContent, headersList)
            .then((res) => {
                setPOST_KWH_HYBRID_DATA_DASHBOARD(res?.data?.KWH_HYBRID[0])
                // console.log('apiDash', res?.data)
                // setPOST_KWH_LOADING(false)
            }).catch((e) => {
                console.log('getHYBRD_ERROR', e)
                // setPOST_KWH_LOADING(false)
            }).finally(() => {
                // setPOST_KWH_LOADING(false)

            })
    }
    function getHYBRDControls(LOGIN_ID, LOC_CODE, PASS_KEY, WEB_KEY, TYP, IROW, DT1, DT2) {
        // console.log('getHYBRD.............')
        setHYBRD_CONTROLS_LOADING(true)
        let headersList = {
            "Accept": "/",
            "Content-Type": "application/x-www-form-urlencoded"
        }
        AxiosApi.get(`CMN_03/Get_HYBRD?LOGIN_ID=${LOGIN_ID}&LOC_CODE=${LOC_CODE}&PASS_KEY=${PASS_KEY}&WEB_KEY=${WEB_KEY}&TYP=${TYP}&IROW=${IROW}&DT1=${DT1}&DT2=${DT2}`, headersList)
            .then((res) => {
                // this data is render on setvalue and control button
                setHYBRD_CONTROLS_DATA(res?.data?.HYBRD[0])
                console.log('Controlls', res?.data)
                setHYBRD_CONTROLS_LOADING(false)
            }).catch((e) => {
                console.log('getHYBRD_ERROR', e)
                setHYBRD_CONTROLS_LOADING(false)
            }).finally(() => {
                setHYBRD_CONTROLS_LOADING(false)

            })
    }
    function getHYBRDsetValues(LOGIN_ID, LOC_CODE, PASS_KEY, WEB_KEY, TYP, IROW, DT1, DT2) {
        // console.log('getHYBRD.............')
        setHYBRD_SETVALUE_LOADING(true)
        let headersList = {
            "Accept": "/",
            "Content-Type": "application/x-www-form-urlencoded"
        }
        AxiosApi.get(`CMN_03/Get_HYBRD?LOGIN_ID=${LOGIN_ID}&LOC_CODE=${LOC_CODE}&PASS_KEY=${PASS_KEY}&WEB_KEY=${WEB_KEY}&TYP=${TYP}&IROW=${IROW}&DT1=${DT1}&DT2=${DT2}`, headersList)
            .then((res) => {
                setHYBRD_SET_VALUES_DATA(res?.data?.HYBRD[0])
                // console.log('DATAAaaaaa', res?.data)
                setHYBRD_SETVALUE_LOADING(false)
            }).catch((e) => {
                console.log('getHYBRD_ERROR', e)
                setHYBRD_SETVALUE_LOADING(false)
            }).finally(() => {
                setHYBRD_SETVALUE_LOADING(false)

            })
    }

    function getHYBRD_Control_Log1(LOGIN_ID, LOC_CODE, PASS_KEY, WEB_KEY, TYP, IROW, DT1, DT2) {
        console.log('LOGPARAMS', { LOGIN_ID, LOC_CODE, PASS_KEY, WEB_KEY, TYP, IROW, DT1, DT2 })
        setHYBRD_CONTROLS_LOG_DATA_LOADING(true)
        let headersList = {
            "Accept": "/",
            "Content-Type": "application/x-www-form-urlencoded"
        }
        AxiosApi.get(`CMN_03/Get_HYBRD?LOGIN_ID=${LOGIN_ID}&LOC_CODE=${LOC_CODE}&PASS_KEY=${PASS_KEY}&WEB_KEY=${WEB_KEY}&TYP=${TYP}&IROW=${IROW}&DT1=${DT1}&DT2=${DT2}`, headersList)
            .then((res) => {
                setHYBRD_CONTROLS_LOG_DATA(res?.data?.HYBRD)
                console.log('LOG', res?.data)
                setHYBRD_CONTROLS_LOG_DATA_LOADING(false)
            }).catch((e) => {
                console.log('getHYBRD_ERROR', e)
                setHYBRD_CONTROLS_LOG_DATA_LOADING(false)
            }).finally(() => {
                setHYBRD_CONTROLS_LOG_DATA_LOADING(false)

            })
    }

    function getPostSetValueSaveButton(LOGIN_ID, PASSWORD, PASSWORD2, VAL, PARA) {

        if (PARA == "GRID_DG_CURRENT_LIMIT") {
            setGridDG(true)
        }
        if (PARA == "DG_RUN_TIME_MIN") {
            setDGRun(true)
        }
        if (PARA == "DG_START_VOLTAGE_V") {
            setDGStart(true)
        }
        if (PARA == "ABSORPTION_TIME_MIN") {
            setAbsorption(true)
        }

        if (PARA == "BULK_VOLTAGE_V") {
            setBulk(true)
        }
        if (PARA == "FLOAT_VOLTAGE_V") {
            setFloat(true)
        }
        if (PARA == "LOW_CUTOFF_V") {
            setLowCut(true)
        }
        if (PARA == "AC_CHARGER_START_VOLTAGE_V") {
            setsACChargerstart(true)
        }

        if (PARA == "AC_CHARGER_STOP_VOLTAGE_V") {
            setACChargerstop(true)
        }

        if (PARA == "MIN_LOAD_THRESHOLD_W") {

            setMinload(true)
        }

        if (PARA == "EXPORT_POWER_LIMIT_PERCENT") {

            setExportpower(true)
        }
        if (PARA == "BATTERY_AH") {
            setBatteryAh(true)
        }
        if (PARA == "BATTERY_CHARGING_CURRENT_A") {
            setBatterycharging(true)
        }
        if (PARA == "MPPT_MODE") {
            setMPPTMode(true)
        }
        if (PARA == "INV_MODE") {
            setINVMode(true)
        }
        if (PARA == "KEY_CODE") {
            setKey(true)
        }
        if (PARA == "BATTERY_LOW_ALARM") {

            setBatteryLow(true)
        }
        if (PARA == "EXPORT_START_LEVEL") {
            setExportstart(true)
        }

        if (PARA == "EXPORT_STOP_LEVEL") {

            setExportstop(true)
        }



        const headersList = {
            "Accept": "/",
            "Content-Type": "application/x-www-form-urlencoded"
        };

        const bodyContent = new URLSearchParams({
            LOGIN_ID,
            PASSWORD,
            PASSWORD2,
            VAL,
            PARA
        }).toString();





        AxiosApi.post(`https://log.solaramr.com/api/CMD_01/POST_CMD2`, bodyContent, { headers: headersList })
            .then((res) => {
                const message = res?.data?.data;
                alert(message);
                setGridDG(false)
                setDGRun(false)
                setDGStart(false)
                setAbsorption(false)
                setBulk(false)
                setFloat(false)
                setLowCut(false)
                setsACChargerstart(false)
                setACChargerstop(false)
                setMinload(false)
                setExportpower(false)
                setBatteryAh(false)
                setBatterycharging(false)
                setMPPTMode(false)
                setINVMode(false)
                setKey(false)
                setBatteryLow(false)
                setExportstart(false)
                setExportstop(false)





            })
            .catch((error) => {
                console.error('Error occurred during API call:', error);
                alert("An error occurred. Please try again later.");
            })
            .finally(() => {
                setGridDG(false)
                setDGRun(false)
                setDGStart(false)
                setAbsorption(false)
                setBulk(false)
                setFloat(false)
                setLowCut(false)
                setsACChargerstart(false)
                setACChargerstop(false)
                setMinload(false)
                setExportpower(false)
                setBatteryAh(false)
                setBatterycharging(false)
                setMPPTMode(false)
                setINVMode(false)
                setKey(false)
                setBatteryLow(false)
                setExportstart(false)
                setExportstop(false)
            });
    }


    function getPostvalueformControlSwitch(LOGIN_ID, PASSWORD, PASSWORD2, VAL, PARA) {
        setSET_VALUE_SAVE_BUTTON_LOADING(true);
        const headersList = {
            "Accept": "/",
            "Content-Type": "application/x-www-form-urlencoded"
        };

        const bodyContent = new URLSearchParams({
            LOGIN_ID,
            PASSWORD,
            PASSWORD2,
            VAL,
            PARA
        }).toString();

        AxiosApi.post(`https://log.solaramr.com/api/CMD_01/POST_CMD1`, bodyContent, { headers: headersList })
            .then((res) => {
                setCONTROL_VALUE_SAVE_SWITCH(res?.data);
                const message = res?.data?.data;
                alert(message);
            })
            .catch((error) => {
                console.error('Error occurred during API call:', error);
                alert("An error occurred. Please try again later.");
            })
            .finally(() => {
                setSET_VALUE_SAVE_BUTTON_LOADING(false);
            });
    }


    function getAnFlagFromInverterTable(data) {
        setEXCEL_FLAG_VALUE(data)

    }





    return (
        <InverterContext.Provider value={{
            fdGridDG,
            fdDGRun,
            fdDGStart,
            fdAbsorption,
            fdBulk,
            fdFloat,
            fdLowCut,
            fdACChargerstart,
            fdACChargerstop,
            fdMinload,
            fdExportpower,
            fdBatteryAh,
            fdBatterycharging,
            fdMPPTMode,
            fdINVMode,
            fdKey,
            fdBatteryLow,
            fdExportstart,
            fdExportstop,
            SET_VALUE_SAVE_BUTTON_LOADING,
            setEXCEL_FLAG_VALUE,
            EXCEL_FLAG_VALUE,
            getAnFlagFromInverterTable,


            CONTROL_VALUE_SAVE_SWITCH, getPostvalueformControlSwitch, SET_VALUE_SAVE_BUTTON, SET_VALUE_SAVE_BUTTON_LOADING, getPostSetValueSaveButton, getHYBRD_Control_Log1, HYBRD_CONTROLS_LOG_DATA, HYBRD_CONTROLS_LOG_DATA_LOADING, getHYBRDControls, getHYBRDsetValues, HYBRD_SETVALUE_LOADING, HYBRD_SET_VALUES_DATA, HYBRD_CONTROLS_DATA, HYBRD_CONTROLS_LOADING, HYBRD_DATA_POWER_Loading, getHYBRD_Info, HYBRD_DATA_INFO, getHYBRD_Power, HYBRD_DATA_POWER, getPostKwhHybridEnergyOverview, EnergyKwhOverviewData, POST_KWH_HYBRID_DATA_DASHBOARD, getPostKwhHybridForDashboard, getPostKwhHybrid, POST_KWH_HYBRID_DATA, HYBRD_MORE_DATA, getHYBRDwithZero, getHYBRD, HYBRD_DATA, HYBRD_LOADING, getGridLoadData, InvChartLoading, getInverterChartData, InverterChartData
        }}>
            {children}
        </InverterContext.Provider>
    )
}


