import { AccountCircle } from '@mui/icons-material'
import { Card, CardActions, Typography, useTheme } from '@mui/material'
import { Stack } from '@mui/system'
import React from 'react'

function InverterList({ Title, Value }) {
    const { palette } = useTheme()
    return (
        <Card>
            <CardActions>
                <Stack flex={1} direction={'row'} justifyContent={'space-between'}>
                    <Stack>
                        <AccountCircle fontSize='small' />
                        <Typography variant='subtitle1' sx={{ fontWeight: 'bold' }}>{Title}</Typography>
                    </Stack>
                    <Typography variant='h5' color={palette.secondary.main} style={{ alignSelf: 'end', fontWeight: 'bold' }}>{Value}</Typography>
                </Stack>
            </CardActions>
        </Card>)
}

export default InverterList