import React, { memo, useContext, Suspense } from 'react';
import { Routes, Route } from 'react-router-dom';
import { AuthenticationContext } from '../context/AuthenticationContext';
import { Box } from '@mui/material';
import { SuAuthenticationContext } from '../context/super_user_context/SuAuthenticationContext';
import SuperUserRoute from './stacks/SuperUserRoute';
import SuDashboard from '../pages/admin/super_user/SuDashboard';
import PlantMIS from '../pages/admin/super_user/PlantMIS';
import Analytics from '../pages/admin/super_user/Analytics';
import Analytics3 from '../pages/admin/super_user/Analytics3';
import GisLocation from '../pages/admin/super_user/GisLocation';
import KwhGraph from '../pages/admin/super_user/KwhGraph';
import PlantList from '../pages/admin/super_user/PlantList';
import PlantImageList from '../pages/admin/super_user/PlantImageList';
import EnergyGeneration from '../pages/admin/super_user/analytics2/EnergyGeneration';
import CUF from '../pages/admin/super_user/analytics2/CUF';
import SpecificPower from '../pages/admin/super_user/analytics2/SpecificPower';
import SpecificYield from '../pages/admin/super_user/analytics2/SpecificYield';
import PeakGeneration from '../pages/admin/super_user/analytics2/PeakGeneration';
import { Key, Public } from '@mui/icons-material';
import NewSuperUser from './stacks/newRoutes/NewSuperUser.js';
const PrivateRoute = React.lazy(() => import('./stacks/PrivateRoute'));
const Dashboard = React.lazy(() => import('../pages/admin/page/dashboard/Dashboard'));
const Analysis = React.lazy(() => import('../pages/admin/page/analysis/Analysis'));
const PvMonitoring = React.lazy(() => import('../pages/admin/page/pv_monitoring/PvMonitoring'));
const WMS = React.lazy(() => import('../pages/admin/page/wms/WMS'));
const PublicRoute = React.lazy(() => import('./stacks/PublicRoute'));
const Login = React.lazy(() => import('../pages/auth/Login'));
const EnergyReporting = React.lazy(() => import('../pages/admin/page/energy_reporting/EnergyReporting'));
const Inverter = React.lazy(() => import('../pages/admin/page/inverter/Inverter'));
const Inverter1 = React.lazy(() => import('../pages/admin/page/inverter1/Inverter1'));
const Grid = React.lazy(() => import('../pages/admin/page/grid/Grid'));
const DG = React.lazy(() => import('../pages/admin/page/dg/DG'));
const EnergyMeter = React.lazy(() => import('../pages/admin/page/energy_meter/EnergyMeter'));
const SMB = React.lazy(() => import('../pages/admin/page/smb/SMB'));

const MainRouter = () => {
    const { IsLogged, UserType } = useContext(AuthenticationContext);

    return (
        <Suspense fallback={<div>Loading...</div>}>
            <Routes>
                {IsLogged && UserType === 'User' ? (
                    <Route path="/" element={<PrivateRoute />}>
                        <Route index element={<WMS />} />
                        <Route path="wms" element={<WMS />} />
                        <Route path="*" element={<Box>Page Not Found</Box>} />
                    </Route>
                ) : IsLogged && UserType === 'SUSER' ? (
                    <Route path="/" element={<SuperUserRoute />}>
                        <Route index element={<SuDashboard />} />
                        <Route path="sdashboard" element={<SuDashboard />} />
                        <Route path="analytics" element={<Analytics />} />
                        <Route path="energy-generation" element={<EnergyGeneration />} />
                        <Route path="cuf" element={<CUF />} />
                        <Route path="specific-power" element={<SpecificPower />} />
                        <Route path="specific-yield" element={<SpecificYield />} />
                        <Route path="peak-generation" element={<PeakGeneration />} />
                        <Route path="analytics3" element={<Analytics3 />} />
                        <Route path="gis-location" element={<GisLocation />} />
                        <Route path="kwh-graph" element={<KwhGraph />} />
                        <Route path="plant-list" element={<PlantList />} />
                        <Route path="plant-image-list" element={<PlantImageList />} />
                        <Route path="plant-mis" element={<PlantMIS />} />
                        <Route path="*" element={<Box>Page Not Found</Box>} />
                    </Route>


                ) : (
                    <Route path="login" element={<PublicRoute />}>
                        <Route index element={<Login />} />
                        <Route path="*" element={<Box>Page Not Found</Box>} />
                    </Route>
                )}
            </Routes>


        </Suspense>
    );
};

export default memo(MainRouter);
