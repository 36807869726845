import { AccountCircle } from '@mui/icons-material'
import { Box, Button, Card, CardActions, CssBaseline, Grid, Icon, IconButton, InputBase, Stack, Typography, useTheme } from '@mui/material'
import React from 'react'
import RecordList from '../../../components/SuperUser/dashboard/RecordList'
import Image from 'mui-image'
import UsersIDList from '../../../components/SuperUser/dashboard/UsersIDList'

function SuDashboard() {
    const { palette, spacing, shape: { borderRadius } } = useTheme()
    return (
        <Box>
            <CssBaseline />
            <Stack rowGap={spacing(1)}>
                <Grid container spacing={spacing(1)}>
                    <Grid item lg={1.5} md={3} sm={6} xs={12}>
                        <RecordList Title={'Total Users'} Records={'5'} />
                    </Grid>
                    <Grid item lg={1.5} md={3} sm={6} xs={12}>
                        <RecordList Title={'Total Inverters'} Records={'15'} />
                    </Grid>
                    <Grid item lg={1.5} md={3} sm={6} xs={12}>
                        <RecordList Title={'Live Inverters'} Records={'12'} />
                    </Grid>
                    <Grid item lg={1.5} md={3} sm={6} xs={12}>
                        <RecordList Title={'Offline Inverters'} Records={'3'} />
                    </Grid>
                    <Grid item lg={1.5} md={3} sm={6} xs={12}>
                        <RecordList Title={'Total Capacity'} Records={'2043.3 [KWp]'} />
                    </Grid>
                    <Grid item lg={1.5} md={3} sm={6} xs={12}>
                        <RecordList Title={'Total Generation'} Records={'1241806 [KWh]'} />
                    </Grid>
                    <Grid item lg={1.5} md={3} sm={6} xs={12}>
                        <RecordList Title={'Revenue Generated'} Records={'8035622 [₹]'} />
                    </Grid>
                    <Grid item lg={1.5} md={3} sm={6} xs={12}>
                        <RecordList Title={'CO2 Reduction'} Records={'741358.2 [Ton]'} />
                    </Grid>
                </Grid>
                <Stack component={Card} columnGap={spacing(1)} p={spacing(1)} direction={'row'} alignItems={'center'}>
                    <InputBase placeholder='search users' style={{ border: `1px solid ${palette.primary.light}`, padding: spacing(0.8), borderRadius: borderRadius }} />
                    <Button variant='outlined'>Search</Button>
                </Stack>
                <Grid container spacing={spacing(1)}>
                    <Grid item lg={2.4} md={3} sm={6} xs={12} >
                        <UsersIDList Logo={
                            <Image width={spacing(25)} style={{ alignSelf: 'center' }} src={require('../../../assets/img/logos/logo 2.png')} />
                        } />
                    </Grid>
                    <Grid item lg={2.4} md={3} sm={6} xs={12} >
                        <UsersIDList Logo={
                            <Image width={spacing(25)} style={{ alignSelf: 'center' }} src={require('../../../assets/img/logos/logo 2.png')} />
                        } />
                    </Grid>
                    <Grid item lg={2.4} md={3} sm={6} xs={12} >
                        <UsersIDList Logo={
                            <Image width={spacing(25)} style={{ alignSelf: 'center' }} src={require('../../../assets/img/logos/logo 2.png')} />
                        } />
                    </Grid>
                    <Grid item lg={2.4} md={3} sm={6} xs={12} >
                        <UsersIDList Logo={
                            <Image width={spacing(25)} style={{ alignSelf: 'center' }} src={require('../../../assets/img/logos/logo 2.png')} />
                        } />
                    </Grid>
                    <Grid item lg={2.4} md={3} sm={6} xs={12} >
                        <UsersIDList Logo={
                            <Image width={spacing(25)} style={{ alignSelf: 'center' }} src={require('../../../assets/img/logos/logo 2.png')} />
                        } />
                    </Grid>
                </Grid>

            </Stack>
        </Box>
    )
}

export default SuDashboard
