import { Cached, RefreshRounded } from '@mui/icons-material';
import { Box, Button, Card, CardActions, Checkbox, CssBaseline, FormControlLabel, FormGroup, IconButton, InputBase, Stack, Typography, useTheme } from '@mui/material'
import React from 'react'

function Analytics3() {
    const { palette, spacing, shape: { borderRadius } } = useTheme()

    const [state, setState] = React.useState({
        gilad: true,
        jason: false,
        antoine: false,
    });

    const handleChange = (event) => {
        setState({
            ...state,
            [event.target.name]: event.target.checked,
        });
    };

    const { gilad, jason, antoine } = state;
    const error = [gilad, jason, antoine].filter((v) => v).length !== 2;


    return (
        <Box>
            <CssBaseline />
            <Stack rowGap={spacing(1)}>
                <Card>
                    <CardActions>
                        <Stack>
                            <Typography variant='h5'>Plant Wise CUF Comparison</Typography>
                            <FormGroup row>
                                <FormControlLabel
                                    control={
                                        <Checkbox size='small' checked={gilad} onChange={handleChange} name="Geeta Threads Limited" />
                                    }
                                    label="Geeta Threads Limited"
                                />
                                <FormControlLabel
                                    control={
                                        <Checkbox size='small' checked={jason} onChange={handleChange} name="Bajaj Textile Mill" />
                                    }
                                    label="Bajaj Textile Mill"
                                />
                                <FormControlLabel
                                    control={
                                        <Checkbox size='small' checked={antoine} onChange={handleChange} name="Gulati Oil India Pvt Ltd" />
                                    }
                                    label="Gulati Oil India Pvt Ltd"
                                />
                                <FormControlLabel
                                    control={
                                        <Checkbox size='small' checked={antoine} onChange={handleChange} name="Kapil Garments" />
                                    }
                                    label="Kapil Garments"
                                />
                                <FormControlLabel
                                    control={
                                        <Checkbox size='small' checked={antoine} onChange={handleChange} name="ATUL FASTENERS" />
                                    }
                                    label="ATUL FASTENERS"
                                />
                            </FormGroup>
                            <Stack direction={'row'} alignItems={'center'} columnGap={spacing(1)}>
                                <Stack direction={'row'} columnGap={spacing(1)} alignItems={'center'}>
                                    <Typography variant='body1'>Ideal Value</Typography>
                                    <InputBase placeholder='search users' style={{ border: `1px solid ${palette.primary.light}`, paddingInline: spacing(1), borderRadius: borderRadius }} />
                                </Stack>
                                <Stack direction={'row'} alignItems={'center'} columnGap={spacing(1)}>
                                    <Typography>From Date</Typography>
                                    <input type='date' className='searchField' />
                                </Stack>
                                <Stack direction={'row'} alignItems={'center'} columnGap={spacing(1)}>
                                    <Typography>To Date</Typography>
                                    <input type='date' className='searchField' />
                                </Stack>
                                <IconButton sx={{ fontSize: spacing(1) }}>
                                    <Cached mode fontSize={'small'} />
                                </IconButton>
                                <Button variant='outlined' size='medium'>Plant Wise CUF Comparison</Button>
                            </Stack>
                        </Stack>
                    </CardActions>
                </Card>
                <Card>
                    <CardActions>
                        <Stack>
                            <Typography variant='h5'>Plant Wise PR Comparison</Typography>
                            <FormGroup row>
                                <FormControlLabel
                                    control={
                                        <Checkbox size='small' checked={gilad} onChange={handleChange} name="Geeta Threads Limited" />
                                    }
                                    label="Geeta Threads Limited"
                                />
                                <FormControlLabel
                                    control={
                                        <Checkbox size='small' checked={jason} onChange={handleChange} name="Bajaj Textile Mill" />
                                    }
                                    label="Bajaj Textile Mill"
                                />
                                <FormControlLabel
                                    control={
                                        <Checkbox size='small' checked={antoine} onChange={handleChange} name="Gulati Oil India Pvt Ltd" />
                                    }
                                    label="Gulati Oil India Pvt Ltd"
                                />
                                <FormControlLabel
                                    control={
                                        <Checkbox size='small' checked={antoine} onChange={handleChange} name="Kapil Garments" />
                                    }
                                    label="Kapil Garments"
                                />
                                <FormControlLabel
                                    control={
                                        <Checkbox size='small' checked={antoine} onChange={handleChange} name="ATUL FASTENERS" />
                                    }
                                    label="ATUL FASTENERS"
                                />
                            </FormGroup>
                            <Stack direction={'row'} alignItems={'center'} columnGap={spacing(1)}>
                                <Stack direction={'row'} columnGap={spacing(1)} alignItems={'center'}>
                                    <Typography variant='body1'>Ideal Value</Typography>
                                    <InputBase placeholder='search users' style={{ border: `1px solid ${palette.primary.light}`, paddingInline: spacing(1), borderRadius: borderRadius }} />
                                </Stack>
                                <Stack direction={'row'} columnGap={spacing(1)} alignItems={'center'}>
                                    <Typography variant='body1'>Higher Value</Typography>
                                    <InputBase placeholder='search users' style={{ border: `1px solid ${palette.primary.light}`, paddingInline: spacing(1), borderRadius: borderRadius }} />
                                </Stack>
                                <Stack direction={'row'} alignItems={'center'} columnGap={spacing(1)}>
                                    <Typography>From Date</Typography>
                                    <input type='date' className='searchField' />
                                </Stack>
                                <Stack direction={'row'} alignItems={'center'} columnGap={spacing(1)}>
                                    <Typography>To Date</Typography>
                                    <input type='date' className='searchField' />
                                </Stack>
                                <IconButton sx={{ fontSize: spacing(1) }}>
                                    <Cached mode fontSize={'small'} />
                                </IconButton>
                                {/* <Button variant='outlined' size='medium'>Plant Wise CUF Comparison</Button> */}
                            </Stack>
                        </Stack>
                    </CardActions>
                </Card>
                <Card>
                    <CardActions>
                        <Stack>
                            <Typography variant='h5'>Plant Wise Specific Yield Comparison</Typography>
                            <FormGroup row>
                                <FormControlLabel
                                    control={
                                        <Checkbox size='small' checked={gilad} onChange={handleChange} name="Geeta Threads Limited" />
                                    }
                                    label="Geeta Threads Limited"
                                />
                                <FormControlLabel
                                    control={
                                        <Checkbox size='small' checked={jason} onChange={handleChange} name="Bajaj Textile Mill" />
                                    }
                                    label="Bajaj Textile Mill"
                                />
                                <FormControlLabel
                                    control={
                                        <Checkbox size='small' checked={antoine} onChange={handleChange} name="Gulati Oil India Pvt Ltd" />
                                    }
                                    label="Gulati Oil India Pvt Ltd"
                                />
                                <FormControlLabel
                                    control={
                                        <Checkbox size='small' checked={antoine} onChange={handleChange} name="Kapil Garments" />
                                    }
                                    label="Kapil Garments"
                                />
                                <FormControlLabel
                                    control={
                                        <Checkbox size='small' checked={antoine} onChange={handleChange} name="ATUL FASTENERS" />
                                    }
                                    label="ATUL FASTENERS"
                                />
                            </FormGroup>
                            <Stack direction={'row'} alignItems={'center'} columnGap={spacing(1)}>
                                <Stack direction={'row'} columnGap={spacing(1)} alignItems={'center'}>
                                    <Typography variant='body1'>Ideal Value</Typography>
                                    <InputBase placeholder='search users' style={{ border: `1px solid ${palette.primary.light}`, paddingInline: spacing(1), borderRadius: borderRadius }} />
                                </Stack>
                                <Stack direction={'row'} alignItems={'center'} columnGap={spacing(1)}>
                                    <Typography>From Date</Typography>
                                    <input type='date' className='searchField' />
                                </Stack>
                                <Stack direction={'row'} alignItems={'center'} columnGap={spacing(1)}>
                                    <Typography>To Date</Typography>
                                    <input type='date' className='searchField' />
                                </Stack>
                                <IconButton sx={{ fontSize: spacing(1) }}>
                                    <Cached mode fontSize={'small'} />
                                </IconButton>
                                <Button variant='outlined' size='medium'>Plant Wise Specific Yield Comparison</Button>
                            </Stack>
                        </Stack>
                    </CardActions>
                </Card>
            </Stack>
        </Box>
    )
}

export default Analytics3
