import React, { createContext, useContext, useEffect, useState } from 'react'
// import AxiosApi from '../api/AxiosApi';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import AxiosApi from '../../api/AxiosApi';
import { AuthenticationContext } from '../AuthenticationContext';

export const PlantListContext = createContext()
export function PlantListProvider({ children }) {
    const Navigate = useNavigate()

    const [PLANT_LIST_DATA, SET_PLANT_LIST_DATA] = useState([]);
    const [PLANT_LIST_DATA_LOADING, SET_PLANT_LIST_DATA_LOADING] = useState(false);



    function getPlantListData() {
        SET_PLANT_LIST_DATA_LOADING(true)
        const url = `https://log.solaramr.com/api/CMN_05/GetUser?suser=PVTECH&KEY=CzBeYQc8MjOZuwwWYqQYg==`;
        const headers = {
            "Accept": "/",
            "Content-Type": "application/x-www-form-urlencoded"
        };

        AxiosApi.get(url, { headers })
            .then((res) => {
                // console.log("ResponseNew:", res.data);
                SET_PLANT_LIST_DATA(res?.data?.data);
                SET_PLANT_LIST_DATA_LOADING(false)
                // Do something with the response if needed
            })
            .catch((error) => {
                SET_PLANT_LIST_DATA_LOADING(false)

                console.error('Error occurred:', error);
            }).catch((e) => {
                SET_PLANT_LIST_DATA_LOADING(false)

                console.log('getHYBRD_ERROR', e)

            }).finally(() => {
                SET_PLANT_LIST_DATA_LOADING(false)

            });
    }




    return (
        <PlantListContext.Provider value={{ PLANT_LIST_DATA, PLANT_LIST_DATA_LOADING, getPlantListData }}>
            {children}
        </PlantListContext.Provider>
    )
}





