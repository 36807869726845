import React from 'react';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
} from 'chart.js';
import { Bar } from 'react-chartjs-2';
// import { faker } from '@faker-js/faker';
import { useTheme } from '@mui/material';

ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
);

export default function BarChart() {
    const { palette } = useTheme()
    const labels = ['12 oct 2023', '12 oct 2023', '12 oct 2023', '12 oct 2023', '12 oct 2023'];


    return <Bar style={{}} options={{
        responsive: true,
        maintainAspectRatio: true,
        plugins: {
            legend: {
                position: 'top',
                display: true
            },
            title: {
                display: true,
                position: 'left',
                text: 'Last day generation kwh today generation kwh',
            },
        },
        spacing: 1,
        aspectRatio: 3
    }} data={{
        labels: labels,
        datasets: [
            {
                label: 'Dataset 1',
                data: [23, 53, 75, 28, 98],
                backgroundColor: palette.primary.main,
                // borderWidth: 10,
                barPercentage: 0.5
            },
            {
                label: 'Dataset 2',
                data: [23, 53, 75, 28, 98],
                backgroundColor: palette.primary.main,
                barPercentage: 0.5

            },
        ]
    }} />;
}
