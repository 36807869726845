import React, { lazy, Suspense } from 'react';
import { Route, Routes } from 'react-router-dom';
import { Box } from '@mui/material';
import SuperUserRoute from '../SuperUserRoute';

const SuDashboard = lazy(() => import('../../../pages/admin/super_user/SuDashboard'));
const Analytics = lazy(() => import('../../../pages/admin/super_user/Analytics'));
const EnergyGeneration = lazy(() => import('../../../pages/admin/super_user/analytics2/EnergyGeneration'));
const CUF = lazy(() => import('../../../pages/admin/super_user/analytics2/CUF'));
const SpecificPower = lazy(() => import('../../../pages/admin/super_user/analytics2/SpecificPower'));
const SpecificYield = lazy(() => import('../../../pages/admin/super_user/analytics2/SpecificYield'));
const PeakGeneration = lazy(() => import('../../../pages/admin/super_user/analytics2/PeakGeneration'));
const Analytics3 = lazy(() => import('../../../pages/admin/super_user/Analytics3'));
const GisLocation = lazy(() => import('../../../pages/admin/super_user/GisLocation'));
const KwhGraph = lazy(() => import('../../../pages/admin/super_user/KwhGraph'));
const PlantList = lazy(() => import('../../../pages/admin/super_user/PlantList'));
const PlantImageList = lazy(() => import('../../../pages/admin/super_user/PlantImageList'));
const PlantMIS = lazy(() => import('../../../pages/admin/super_user/PlantMIS'));

function NewSuperUser() {
    return (
        <Suspense fallback={<Box>Loading...</Box>}>
            <Routes>
                <Route path="/" element={<SuperUserRoute />}>
                    <Route index element={<SuDashboard />} />
                    <Route path="analytics" element={<Analytics />} />
                    <Route path="energy-generation" element={<EnergyGeneration />} />
                    <Route path="cuf" element={<CUF />} />
                    <Route path="specific-power" element={<SpecificPower />} />
                    <Route path="specific-yield" element={<SpecificYield />} />
                    <Route path="peak-generation" element={<PeakGeneration />} />
                    <Route path="analytics3" element={<Analytics3 />} />
                    <Route path="gis-location" element={<GisLocation />} />
                    <Route path="kwh-graph" element={<KwhGraph />} />
                    <Route path="plant-list" element={<PlantList />} />
                    <Route path="plant-image-list" element={<PlantImageList />} />
                    <Route path="plant-mis" element={<PlantMIS />} />
                    <Route path="*" element={<Box>Page Not Found</Box>} />
                </Route>
            </Routes>
        </Suspense>
    );
}

export default NewSuperUser;
