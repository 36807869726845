// import { Chart } from 'chart.js/dist';
// import { Chart } from 'chart.js';
import { useTheme } from '@mui/material';
import React, { useEffect } from 'react';
import { Bar, Line } from 'react-chartjs-2';
// import Chart from 'chart.js';
const EnergyBarChart = ({ labels, data }) => {
    const { palette } = useTheme()
    return (
        <Bar style={{ overflow: 'scroll' }}
            options={{
                responsive: true,
                maintainAspectRatio: true,
                animation: false,
                plugins: {
                    legend: {
                        position: 'top',
                        display: true,
                        labels: {
                            color: palette.common.black
                        }
                    },
                    title: {
                        display: false,
                        position: 'left',
                        text: 'Current',
                    },
                    tooltip: {
                        mode: 'index',
                        intersect: false,
                    },
                    filler: {

                        propagate: true
                    }

                },
                spacing: 1,

                aspectRatio: 3,
                scales: {
                    y: {
                        ticks: {
                            // color: 'inherit',
                            font: {
                                size: 11,
                                weight: 'bold',
                            },
                            color: palette.common.black,

                        }
                    },
                    x: {
                        ticks: {
                            // color: 'inherit',
                            font: {
                                size: 11,
                                weight: 'bold',
                            },
                            color: palette.common.black
                        }
                    }
                }
            }}
            data={{
                labels: labels,
                datasets: data

            }} />

    );
};

export default EnergyBarChart;
