import axios from "axios";
const BaseUrl = "https://log.solaramr.com/API/";

export default axios.create({
    baseURL: BaseUrl
})

// export const LOC_CODE = localStorage.getItem('LCODE')
// export const LOGIN_ID = localStorage.getItem('LoginID')
export const PASS_KEY = "xyz"
export const WEB_KEY = "WEBXYZ321"
export const DATA_TYPE = "1"
export const DT1 = "01-DEC-2023"
export const DT2 = "18-DEC-2023"

// for HYBRD

