

import React, { createContext, useEffect, useRef, useState } from 'react'
// import AxiosApi, { DATA_TYPE, DT1, DT2, LOC_CODE, LOGIN_ID, PASS_KEY, WEB_KEY } from '../api/AxiosApi';
import { ToastContainer, toast } from 'react-toastify';
import AxiosApi from '../api/AxiosApi';

export const PvMonitoringContext = createContext()
export function PvMonitoringProvider({ children }) {
    const [PvMnMenuData, setPvMnMenuData] = useState([])
    const [PvMnMenuData_INV, setPvMnMenuData_INV] = useState([])
    const [Pv_MnMenu_INV_Loading, setPv_MnMenu_INV_Loading] = useState(false)
    const [PvMnMenuLoading, setPvMnMenuLoading] = useState(false)
    const [PvMnPostStringData, setPvMnPostStringData] = useState([])
    const [PvMnViewMorePostStringData, setPvMnPostViewMoreStringData] = useState([])
    const [StringMonitoringData, setStringMonitoringData] = useState([])
    const [PvMnPostStringLoading, setPvMnPostStringLoading] = useState(false)
    const [StringMonitoringLoading, setStringMonitoringLoading] = useState(false)
    const [MpptMonitoringData, setMpptMonitoringData] = useState([])
    const [MpptMonitoringLoading, setMpptMonitoringLoading] = useState(false)

    function getPvMnMenu(LOGIN_ID, LOC_CODE, PASS_KEY, WEB_KEY) {
        // console.log('PARAMS', { LOGIN_ID, LOC_CODE, PASS_KEY, WEB_KEY })
        setPvMnMenuLoading(true)
        let headersList = {
            "Accept": "/",
            "Content-Type": "application/x-www-form-urlencoded"
        }
        AxiosApi.get(`CMN_02/Get_FEED_LIST?LOGIN_ID=${LOGIN_ID}&LOC_CODE=${LOC_CODE}&PASS_KEY=${PASS_KEY}&WEB_KEY=${WEB_KEY}`, headersList)
            .then((res) => {
                setPvMnMenuData(res?.data?.data)
                // console.log('PvMnMenu', res?.data?.data)
                setPvMnMenuLoading(false)

            }).catch((er) => {
                console.log('errorOnGettingPvMnMenu', er)
                setPvMnMenuLoading(false)

            }).finally(() => {
                setPvMnMenuLoading(false)

            });

    }

    function getPvMnMenu_INV(LOGIN_ID, LOC_CODE, PASS_KEY, WEB_KEY, TYE) {
        console.log('PARAMSssssssINV', { LOGIN_ID, LOC_CODE, PASS_KEY, WEB_KEY, TYE })
        setPv_MnMenu_INV_Loading(true)
        let headersList = {
            "Accept": "/",
            "Content-Type": "application/x-www-form-urlencoded"
        }
        AxiosApi.get(`CMN_02/Get_FEED_LIST_INV?LOGIN_ID=${LOGIN_ID}&LOC_CODE=${LOC_CODE}&PASS_KEY=${PASS_KEY}&WEB_KEY=${WEB_KEY}&TYE=${TYE}`, headersList)
            .then((res) => {
                setPvMnMenuData_INV(res?.data?.data)
                console.log('PvMnMenu_INV', res?.data?.data)
                setPv_MnMenu_INV_Loading(false)

            }).catch((er) => {
                console.log('errorOnGettingPvMnMenu', er)
                setPv_MnMenu_INV_Loading(false)

            }).finally(() => {
                setPv_MnMenu_INV_Loading(false)

            });

    }

    function getPvMnPostString(LOGIN_ID, LOC_CODE, PASS_KEY, WEB_KEY, ID, DT1, DT2, ROW) {
        // console.log('prmas', { LOGIN_ID, LOC_CODE, PASS_KEY, WEB_KEY, ID, DT1, DT2, ROW })
        setPvMnPostStringLoading(true)
        let headersList = {
            "Accept": "/",
            "Content-Type": "application/x-www-form-urlencoded"
        }
        let bodyContent = `LOC_CODE=${LOC_CODE}&LOGIN_ID=${LOGIN_ID}&PASS_KEY=${PASS_KEY}&WEB_KEY=${WEB_KEY}&ID=${ID}&DT1=${DT1}&DT2=${DT2}&ROW=${ROW}`;
        AxiosApi.post('CMN_02/POST_STRING', bodyContent, headersList)
            .then((res) => {
                setPvMnPostStringData(res?.data?.data)
                setPvMnPostStringLoading(false)
                // console.log('PvMnPostString', res?.data?.data)

            }).catch((er) => {
                console.log('errorOnGettingPvMnPostString', er)
                setPvMnPostStringLoading(false)
            });
    }

    function getPvMnViewMorePostString(LOGIN_ID, LOC_CODE, PASS_KEY, WEB_KEY, ID, DT1, DT2, ROW2) {
        // console.log('prmas', { LOGIN_ID, LOC_CODE, PASS_KEY, WEB_KEY, ID, DT1, DT2, ROW2 })
        setPvMnPostStringLoading(true)
        let headersList = {
            "Accept": "/",
            "Content-Type": "application/x-www-form-urlencoded"
        }
        let bodyContent = `LOC_CODE=${LOC_CODE}&LOGIN_ID=${LOGIN_ID}&PASS_KEY=${PASS_KEY}&WEB_KEY=${WEB_KEY}&ID=${ID}&DT1=${DT1}&DT2=${DT2}&ROW=${ROW2}`;
        AxiosApi.post('CMN_02/POST_STRING', bodyContent, headersList)
            .then((res) => {
                setPvMnPostViewMoreStringData(res?.data?.STRING)
                setPvMnPostStringLoading(false)
                // console.log('PvMnPostStringVM', res?.data?.STRING)

            }).catch((er) => {
                console.log('errorOnGettingPvMnPostString', er)
                setPvMnPostStringLoading(false)
            });
    }


    function getStringMonitoring(LOGIN_ID, LOC_CODE, PASS_KEY, WEB_KEY, ID, DT1, DT2, ROW2) {
        // console.log('StrMoniParams', { LOGIN_ID, LOC_CODE, PASS_KEY, WEB_KEY, ID, DT1, DT2, ROW2 })
        setStringMonitoringLoading(true)
        let headersList = {
            "Accept": "/",
            "Content-Type": "application/x-www-form-urlencoded"
        }
        let bodyContent = `LOC_CODE=${LOC_CODE}&LOGIN_ID=${LOGIN_ID}&PASS_KEY=${PASS_KEY}&WEB_KEY=${WEB_KEY}&ID=${ID}&DT1=${DT1}&DT2=${DT2}&ROW=${ROW2}`;
        AxiosApi.post('CMN_02/POST_STRING', bodyContent, headersList)
            .then((res) => {
                const newData = res?.data?.STRING?.map(item => {
                    // Create a new object with lowercase 'datetime'
                    const newItem = { DATETIME: item?.DATETIME?.toUpperCase() || item?.DateTime?.toUpperCase(), ...item };
                    // // Remove the original 'DATETIME' key
                    // delete newItem.DATETIME || newItem.DateTime;
                    return newItem;

                });
                setStringMonitoringData([...newData])
                setStringMonitoringLoading(false)
                // console.log('PvStringMoni', newData)

            }).catch((er) => {
                console.log('errorOnGettingPvMnPostString', er)
                setStringMonitoringLoading(false)
            });
    }


    function getMpptMonitoring(LOGIN_ID, LOC_CODE, PASS_KEY, WEB_KEY, ID, DT1, DT2, ROW2) {
        // console.log('MpptMoniParams', { LOGIN_ID, LOC_CODE, PASS_KEY, WEB_KEY, ID, DT1, DT2, ROW2 })
        setMpptMonitoringLoading(true)
        let headersList = {
            "Accept": "/",
            "Content-Type": "application/x-www-form-urlencoded"
        }
        let bodyContent = `LOC_CODE=${LOC_CODE}&LOGIN_ID=${LOGIN_ID}&PASS_KEY=${PASS_KEY}&WEB_KEY=${WEB_KEY}&ID=${ID}&DT1=${DT1}&DT2=${DT2}&ROW=${ROW2}`;
        AxiosApi.post('CMN_02/POST_STRING', bodyContent, headersList)
            .then((res) => {
                const newData = res?.data?.STRING?.map(item => {
                    // Create a new object with lowercase 'datetime'
                    const newItem = { DATETIME: item?.DATETIME?.toUpperCase() || item?.DateTime?.toUpperCase(), ...item };
                    // // Remove the original 'DATETIME' key
                    // delete newItem.DATETIME || newItem.DateTime;
                    return newItem;

                });
                setMpptMonitoringData([...newData])
                setMpptMonitoringLoading(false)
                // console.log('MpptData', res?.data?.STRING)
            }).catch((er) => {
                console.log('errorOnGettingPvMnPostString', er)
                setMpptMonitoringLoading(false)
            });
    }

    return (
        <PvMonitoringContext.Provider value={{ getPvMnMenu_INV, PvMnMenuData_INV, Pv_MnMenu_INV_Loading, MpptMonitoringData, MpptMonitoringLoading, getMpptMonitoring, getStringMonitoring, StringMonitoringLoading, StringMonitoringData, PvMnViewMorePostStringData, getPvMnViewMorePostString, PvMnPostStringLoading, PvMnPostStringData, getPvMnPostString, PvMnMenuLoading, PvMnMenuData, getPvMnMenu }}>
            {children}
        </PvMonitoringContext.Provider>
    )
}
