

import React, { createContext, useEffect, useRef, useState } from 'react'
// import AxiosApi, { DATA_TYPE, DT1, DT2, LOC_CODE, LOGIN_ID, PASS_KEY, WEB_KEY } from '../api/AxiosApi';
import { ToastContainer, toast } from 'react-toastify';
import AxiosApi from '../api/AxiosApi';

export const WmcContext = createContext()
export function WmcProvider({ children }) {
    // const messageRef = useRef();
    const [WmSensData, setWmSensData] = useState([])
    const [SensDataLoading, setSensDataLoading] = useState(false)
    const [MenuItems, setMenuData] = useState([])
    const [MenuLoading, setMenuLoading] = useState(false)
    const [GoToBottom, setGoToBottom] = useState(false)

    const [SingleWmDash, setSingleWmDash] = useState([])
    let MenuData = []
    // const [InvChartLoading, setInvChartLoading] = useState(false)
    // const [WmcMenuData, setWmcMenuData] = useState([])
    const [WmcLoading, setWmcLoading] = useState(false)
    // const [WmcDashData, setWmcDashData] = useState([])
    const [WMCDASHDATA, setWMCDASHDATA] = useState([])
    const [WmcDashTab3Data, setWmcDashTab3Data] = useState([])
    const [WmcSensTab3Data, setWmcSensTab3Data] = useState([])
    const [BrandLogo, setBrandLogo] = useState(null)

    function getImageLogo(LOGIN_ID, LOC_CODE, PASS_KEY, WEB_KEY) {
        console.log('getImageLogoMethod......')
        let headersList = {
            Accept: "/",
            "Content-Type": "application/x-www-form-urlencoded",
        };
        // let data1 = LOGIN_ID = 2201411 & LOC_CODE=354WT & PASS_KEY=xxxxx & WEB_KEY=WEBXYZ321
        // let bodyContent = LOGIN_ID=${LOGIN_ID}&LOC_CODE=${LOC_CODE}&PASS_KEY=${PASS_KEY}&WEB_KEY=${WEB_KEY};
        AxiosApi.get(`https://log.solaramr.com/api/CMN_02/GET_COM_LOGO?LOGIN_ID=${LOGIN_ID}&LOC_CODE=${LOC_CODE}&PASS_KEY=${PASS_KEY}&WEB_KEY=${WEB_KEY}`, headersList)
            .then((res) => {
                // setlogoImageWmc(res?.data);
                console.log("imageLogoaxios", res.data)
                setBrandLogo(res?.data)
            })
            .catch((er) => {
                console.log("fetInvErrorimglogo", er);
            });
    }

    function getWmcMenu(LOC_CODE, LOGIN_ID, PASS_KEY, WEB_KEY, DATA_TYPE, DT1, DT2) {
        // console.log('ParamsTab1', { LOC_CODE, LOGIN_ID, PASS_KEY, WEB_KEY, DATA_TYPE, DT1, DT2 })

        setWmcLoading(true)
        let dataFinal = []

        let headersList = {
            "Accept": "/",
            "Content-Type": "application/x-www-form-urlencoded"
        }
        let bodyContent = `LOC_CODE=${LOC_CODE}&LOGIN_ID=${LOGIN_ID}&PASS_KEY=${PASS_KEY}&WEB_KEY=${WEB_KEY}`;
        AxiosApi.post('CMN_02/WM_MENU', bodyContent, headersList)
            .then((res) => {
                // setMenuData(res?.data?.WM_MENU)
                res?.data?.WM_MENU?.forEach((item, index) => {
                    if (item?.MENU_ID != null) {
                        let bodyContent = `LOC_CODE=${LOC_CODE}&LOGIN_ID=${LOGIN_ID}&PASS_KEY=${PASS_KEY}&WEB_KEY=${WEB_KEY}&WM_TYPE=${item?.MENU_ID}&DATA_TYPE=${DATA_TYPE}&DT1=${DT1}&DT2=${DT2}`;
                        AxiosApi.post('CMN_02/WM_DASH', bodyContent, headersList)
                            .then((res) => {
                                // if (res?.data != null) {
                                const data = {
                                    ...res?.data?.WM_DASH[0],
                                    MENU_NAME: item?.MENU_NAME,
                                    UNIT: item?.unit,
                                    MENU_INDEX: index
                                }
                                dataFinal.push(data)
                                // }
                                // setWmcLoading(false)
                            }).catch((er) => {
                                console.log('fetInvErrorWmcDash', er)
                                setWmcLoading(false)

                            }).finally(() => {
                                setWMCDASHDATA([...dataFinal])
                                setWmcLoading(false)
                            });
                    }

                })
                // console.log('WmMenu', res?.data)

            }).catch((er) => {
                console.log('fetInvErrorWmMenu', er)
                setWmcLoading(false)
            }).finally(() => {
                // setWMCDASHDATA(dataFinal)
                setWmcLoading(false)
            });

    }
    function getTabWmcMenu(LOC_CODE, LOGIN_ID, PASS_KEY, WEB_KEY) {
        setMenuLoading(true)
        let dataFinal = []
        let headersList = {
            "Accept": "/",
            "Content-Type": "application/x-www-form-urlencoded"
        }
        let bodyContent = `LOC_CODE=${LOC_CODE}&LOGIN_ID=${LOGIN_ID}&PASS_KEY=${PASS_KEY}&WEB_KEY=${WEB_KEY}`;
        AxiosApi.post('CMN_02/WM_MENU', bodyContent, headersList)
            .then((res) => {
                setMenuData(res?.data?.WM_MENU)
                setMenuLoading(false)
            }).catch((er) => {
                console.log('fetInvErrorWmMenu', er)
                setMenuLoading(false)
            }).finally(() => {
                // setWMCDASHDATA(dataFinal)
                setMenuLoading(false)
            });

    }
    function getWmcDash(LOC_CODE, LOGIN_ID, PASS_KEY, WEB_KEY, WM_TYPE, DATA_TYPE, DT1, DT2) {
        let headersList = {
            "Accept": "/",
            "Content-Type": "application/x-www-form-urlencoded"
        }
        let bodyContent = `LOC_CODE=${LOC_CODE}&LOGIN_ID=${LOGIN_ID}&PASS_KEY=${PASS_KEY}&WEB_KEY=${WEB_KEY}&WM_TYPE=${WM_TYPE}&DATA_TYPE=${DATA_TYPE}&DT1=${DT1}&DT2=${DT2}`;
        AxiosApi.post('CMN_02/WM_DASH', bodyContent, headersList)
            .then((res) => {
                setSingleWmDash(res?.data?.WM_DASH)
                // console.log('WmDashhhhhhhh', res?.data?.WM_DASH)
            }).catch((er) => {
                console.log('fetInvErrorWmcDash', er)
            });

    }
    function getWmSENS(LOC_CODE, LOGIN_ID, PASS_KEY, WEB_KEY, WM_TYPE, DT1, DT2) {
        setSensDataLoading(true)
        let headersList = {
            "Accept": "/",
            "Content-Type": "application/x-www-form-urlencoded"
        }
        let bodyContent = `LOC_CODE=${LOC_CODE}&LOGIN_ID=${LOGIN_ID}&PASS_KEY=${PASS_KEY}&WEB_KEY=${WEB_KEY}&WM_TYPE=${WM_TYPE}&DT1=${DT1}&DT2=${DT2}`;
        AxiosApi.post('CMN_02/WM_SENS', bodyContent, headersList)
            .then((res) => {
                setWmSensData(res?.data?.WM_DASH)
                // console.log('WmSensData', res?.data?.WM_DASH)
                setSensDataLoading(false)
            }).catch((er) => {
                console.log('fetErrWmSens', er)
                setSensDataLoading(false)

            });
    }


    function getWmSENSTab3Data(LOC_CODE, LOGIN_ID, PASS_KEY, WEB_KEY, WM_TYPE, DT1, DT2) {
        // console.log('ParamsTab3333', { LOC_CODE, LOGIN_ID, PASS_KEY, WEB_KEY, WM_TYPE, DT1, DT2 })
        // setSensDataLoading(true)
        let headersList = {
            "Accept": "/",
            "Content-Type": "application/x-www-form-urlencoded"
        }
        let bodyContent = `LOC_CODE=${LOC_CODE}&LOGIN_ID=${LOGIN_ID}&PASS_KEY=${PASS_KEY}&WEB_KEY=${WEB_KEY}&WM_TYPE=${WM_TYPE}&DT1=${DT1}&DT2=${DT2}`;
        AxiosApi.post('CMN_02/WM_SENS', bodyContent, headersList)
            .then((res) => {
                setWmcSensTab3Data(res?.data?.WM_DASH)
                // console.log('WmSens', res?.data?.WM_DASH)
                // setSensDataLoading(false)
            }).catch((er) => {
                console.log('fetErrWmSens', er)
                // setSensDataLoading(false)

            });
    }

    function getWmcDashTab3(LOC_CODE, LOGIN_ID, PASS_KEY, WEB_KEY, WM_TYPE, DATA_TYPE, DT1, DT2) {
        console.log('DashParamsTab3FromContext', { LOC_CODE, LOGIN_ID, PASS_KEY, WEB_KEY, WM_TYPE, DATA_TYPE, DT1, DT2 })
        let headersList = {
            "Accept": "/",
            "Content-Type": "application/x-www-form-urlencoded"
        }
        let bodyContent = `LOC_CODE=${LOC_CODE}&LOGIN_ID=${LOGIN_ID}&PASS_KEY=${PASS_KEY}&WEB_KEY=${WEB_KEY}&WM_TYPE=${WM_TYPE}&DATA_TYPE=${DATA_TYPE}&DT1=${DT1}&DT2=${DT2}`;
        AxiosApi.post('CMN_02/WM_DASH', bodyContent, headersList)
            .then((res) => {
                setWmcDashTab3Data(res?.data?.WM_DASH)
                // console.log('WmcTab3Dashhhh', res?.data?.WM_DASH)
            }).catch((er) => {
                console.log('fetInvErrorWmcDash', er)
            });

    }


    return (
        <WmcContext.Provider value={{ BrandLogo, getImageLogo, GoToBottom, setGoToBottom, getWmSENSTab3Data, WmcSensTab3Data, WmcDashTab3Data, getWmcDashTab3, MenuLoading, getTabWmcMenu, SensDataLoading, SingleWmDash, getWmcDash, WmSensData, MenuItems, getWmSENS, WmcLoading, WMCDASHDATA, getWmcMenu }}>
            {children}
        </WmcContext.Provider>
    )
}
