import React, { createContext, useEffect, useState } from 'react'
const DEFAULT_COLOR = '#356E35';
export const AppThemeContext = createContext()
export function AppThemeProvider({ children }) {

    // Use a function for the initial state to avoid reading localStorage during component creation
    const getInitialColor = () => {
        const storedColor = localStorage.getItem('UserTheme');
        return storedColor && storedColor.length > 0 ? storedColor : DEFAULT_COLOR;
    };

    const [PrimaryColor, setPrimaryColor] = useState(getInitialColor);
    const [Mode, setMode] = useState('')

    useEffect(() => {
        // Use a single variable for readability and simplify the condition
        const storedColor = localStorage.getItem('UserTheme');
        if (storedColor && storedColor.length > 0) {
            setPrimaryColor(storedColor);
        } else {
            setPrimaryColor(DEFAULT_COLOR);
        }
    }, []);

    return (
        <AppThemeContext.Provider value={{ setPrimaryColor, PrimaryColor }}>
            {children}
        </AppThemeContext.Provider>
    )
}
