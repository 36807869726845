import { Box, Card, CardActions, Stack, Typography, useTheme } from '@mui/material'
import Image from 'mui-image'
import React from 'react'

function UsersIDList({ Logo }) {
    const { spacing } = useTheme()
    return (
        <Card>
            <CardActions sx={{ justifyContent: 'center' }}>
                <Box sx={{ alignItems: 'center', justifyContent: 'center', textAlign: 'center' }}>
                    <Stack justifyContent={'center'} alignItems={'center'}>
                        {Logo}
                    </Stack>
                    <Stack>
                        <Typography variant='subtitle1' sx={{ fontWeight: 'bold' }}>2201411</Typography>
                        <Typography variant='subtitle2' color={'error.main'} sx={{ fontWeight: 'bold' }}>BARNALA</Typography>
                        <Stack direction={'row'} justifyContent={'center'}>
                            <Typography variant='body2' sx={{}}>Total Inverters : </Typography>
                            <Typography variant='body2' color={'error.main'}>3</Typography>
                        </Stack>
                        <Stack direction={'row'} justifyContent={'center'}>
                            <Typography variant='body2' sx={{}}>Plant Capacity : </Typography>
                            <Typography variant='body2' color={'error.main'}>885 Kwp</Typography>
                        </Stack>
                        <Stack direction={'row'} justifyContent={'center'}>
                            <Typography variant='body2' sx={{}}>Status : </Typography>
                            <Typography variant='body2' color={'error.main'}>Partial Offline</Typography>
                        </Stack>
                    </Stack>
                </Box>
            </CardActions>
        </Card>
    )
}

export default UsersIDList
