import { AccountCircle, Person } from '@mui/icons-material'
import { Box, Card, CardActions, CssBaseline, Grid, Icon, IconButton, Stack, Typography, useTheme } from '@mui/material'
import React from 'react'

function RecordList({ Title, Records }) {
    const { palette, spacing } = useTheme()

    return (
        <Card sx={{}}>
            <CardActions sx={{ flexGrow: 1 }}>
                <Stack direction={'column'} flex={1} justifyContent={'start'} alignItems={'start'}>
                    <Stack direction={'row'} alignItems={'center'} columnGap={spacing(1)} >
                        <AccountCircle fontSize='small' />
                        <Stack>
                            <Typography variant='p' style={{ fontWeight: 'bold' }}>{Title}</Typography>
                            <Typography variant='p' color={palette.secondary.main} style={{ fontWeight: 'bold' }}>{Records}</Typography>
                        </Stack>
                    </Stack>
                </Stack>
            </CardActions>
        </Card>
    )
}

export default RecordList
