import { AccountCircle, Person, SolarPower } from '@mui/icons-material'
import { Box, Button, Card, CardActions, CardHeader, Container, CssBaseline, Divider, FormControl, Grid, Icon, IconButton, InputBase, InputLabel, MenuItem, Paper, Select, Stack, Typography, useTheme } from '@mui/material'
import React, { useState } from 'react'
import RecordList from '../../../components/SuperUser/dashboard/RecordList'
import Image from 'mui-image'
import GoogleMapReact from 'google-map-react';
import { Loader } from '@googlemaps/js-api-loader'
import InverterList from '../../../components/SuperUser/InverterList'
import { Table } from 'react-bootstrap'
import SuPagination from '../../../components/SuperUser/general/SuPagination'
function PlantImageList() {
    const { palette, spacing, shape: { borderRadius } } = useTheme()
    const [SelectedType, setSelectedType] = React.useState('');

    const AnyReactComponent = ({ text }) => <div>{text}</div>;

    const position = [51.505, -0.09]
    const defaultProps = {
        center: {
            lat: 10.99835602,
            lng: 77.01502627
        },
        zoom: 11
    };
    const handleChangeSelectedType = (event) => {
        setSelectedType(event.target.value);
    };

    const table_head = ['SN', 'User', 'User_Id', 'Alarm', 'Current_kW', 'Plant_Location', 'Plant_Image1'];

    const table_data = [
        {
            "SN": '1',
            "User": 'Sooraj',
            "User_Id": '23',
            "Alarm": 'Yes',
            "Current_kW": '5',
            "Plant_Location": 'Location 1',
            "Plant_Image1": <input type='file' />
        },
        {
            "SN": '2',
            "User": 'John',
            "User_Id": '42',
            "Alarm": 'No',
            "Current_kW": '8',
            "Plant_Location": 'Location 2',
            "Plant_Image1": <input type='file' />
        },
        {
            "SN": '3',
            "User": 'Alice',
            "User_Id": '55',
            "Alarm": 'Yes',
            "Current_kW": '3',
            "Plant_Location": 'Location 3',
            "Plant_Image1": <input type='file' />
        },
    ];

    const [currentPage, setCurrentPage] = useState(1);
    const [recordsPerPage] = useState(2);
    const indexOfLastRecord = currentPage * recordsPerPage;
    const indexOfFirstRecord = indexOfLastRecord - recordsPerPage;
    const currentRecords = table_data.slice(indexOfFirstRecord, indexOfLastRecord);
    const nPages = Math.ceil(table_data.length / recordsPerPage)
    return (
        <Box>
            <CssBaseline />
            <Stack rowGap={spacing(1)}>
                <Grid container spacing={spacing(1)}>
                    <Grid item lg={3} md={3} sm={6} xs={12}>
                        <InverterList Title={'Total Users'} Value={6} />
                    </Grid>
                    <Grid item lg={3} md={3} sm={6} xs={12}>
                        <InverterList Title={'Total Inverters'} Value={14} />
                    </Grid>
                    <Grid item lg={3} md={3} sm={6} xs={12}>
                        <InverterList Title={'Live Inverters'} Value={9} />
                    </Grid>
                    <Grid item lg={3} md={3} sm={6} xs={12}>
                        <InverterList Title={'Offline Inverters'} Value={2} />
                    </Grid>
                </Grid>
                <Card>
                    <CardActions>
                        <Stack rowGap={spacing(1)} flex={1}>
                            {/* <CardHeader subheader='Plant List' /> */}
                            <Typography py={spacing(1)} variant='body2'>Plant Image List</Typography>
                            {/* <Divider variant='fullWidth' /> */}
                            <Stack direction={'row'} justifyContent={'space-between'} alignItems={'center'}>
                                <Stack direction={'row'} alignItems={'center'} columnGap={spacing(1)}>
                                    <Typography variant='body2'>Show</Typography>
                                    <Box sx={{ minWidth: 150 }}>
                                        <FormControl fullWidth>
                                            <InputLabel id="demo-simple-select-label">Select Type</InputLabel>
                                            <Select
                                                variant='outlined'
                                                labelId="demo-simple-select-label"
                                                id="demo-simple-select"
                                                value={SelectedType}
                                                label='type'
                                                onChange={handleChangeSelectedType}
                                                size='small'
                                            >
                                                <MenuItem value={10}>Energy Generation</MenuItem>
                                                <MenuItem value={20}>CUF</MenuItem>
                                                <MenuItem value={30}>Specific Yield</MenuItem>
                                            </Select>
                                        </FormControl>
                                    </Box>
                                    <Typography variant='body2'>Enteries</Typography>
                                </Stack>
                                <Stack direction={'row'} alignItems={'center'} columnGap={spacing(1)}>
                                    <Stack px={spacing(1)} columnGap={spacing(1)} direction={'row'} alignItems={'center'}>
                                        <Button variant='outlined' size='medium' sx={{}}>Search</Button>
                                        <InputBase placeholder='search users' style={{ border: `1px solid ${palette.primary.light}`, padding: spacing(0.8), borderRadius: borderRadius }} />
                                    </Stack>
                                </Stack>
                            </Stack>
                        </Stack>
                    </CardActions>
                </Card>
                <Card>
                    <CardActions sx={{ flex: 1 }}>
                        <Stack flex={1} rowGap={spacing(1)}>
                            <Button variant='outlined' sx={{ alignSelf: 'start' }}>EXCEL</Button>
                            {
                                currentRecords?.length <= 0 ? (
                                    <Typography variant='body2' color={'error.main'} textAlign={'center'}>Data Not Found</Typography>
                                ) : (
                                    <Box sx={{ flex: 1 }}>
                                        <div className='table-responsive'>
                                            <Table style={{}} id='table-to-pdf' striped bordered hover responsive>
                                                <thead>
                                                    <tr style={{ textAlign: 'center' }}>
                                                        {Object.keys(currentRecords[0]).map((key, index) => (
                                                            <th key={index} style={{ padding: 15, backgroundColor: "#FDF8E4", color: palette.primary.main }}>{key}</th>
                                                        ))}
                                                    </tr>
                                                </thead>

                                                <tbody>
                                                    {
                                                        currentRecords?.map((item, index) => {
                                                            // console.log('data', item)
                                                            return (
                                                                <tr key={index}>
                                                                    {table_head?.map(key =>
                                                                        <td key={key} style={{ textAlign: 'center', padding: spacing(1), whiteSpace: 'nowrap' }} align='center'>{item[key]}</td>
                                                                    )}
                                                                </tr>
                                                            )
                                                        })
                                                    }
                                                </tbody>
                                            </Table>
                                        </div>
                                        <Box
                                            sx={{ display: 'flex', justifyContent: 'end' }}
                                        >
                                            <SuPagination
                                                nPages={nPages}
                                                currentPage={currentPage}
                                                setCurrentPage={setCurrentPage}
                                            />
                                        </Box>
                                    </Box>

                                )
                            }
                        </Stack>

                    </CardActions>
                </Card>


            </Stack>
        </Box>
    )
}

export default PlantImageList
