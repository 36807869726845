import { AccountCircle, Cached, Person, SolarPower } from '@mui/icons-material'
import { Box, Button, Card, CardActions, CardHeader, Container, CssBaseline, Divider, FormControl, Grid, Icon, IconButton, InputBase, InputLabel, MenuItem, Paper, Select, Stack, Typography, useTheme } from '@mui/material'
import React, { useState } from 'react'
import RecordList from '../../../components/SuperUser/dashboard/RecordList'
import Image from 'mui-image'
import GoogleMapReact from 'google-map-react';
import { Loader } from '@googlemaps/js-api-loader'
import InverterList from '../../../components/SuperUser/InverterList'
import { Table } from 'react-bootstrap'
import SuPagination from '../../../components/SuperUser/general/SuPagination'
import EnergyBarChart from '../../../components/inverter/EnergyBarChart'
import BarChart from '../../../components/charts/BarChart'
function KwhGraph() {
    const { palette, spacing, shape: { borderRadius } } = useTheme()
    const [SelectedType, setSelectedType] = React.useState('');

    const AnyReactComponent = ({ text }) => <div>{text}</div>;

    const position = [51.505, -0.09]
    const defaultProps = {
        center: {
            lat: 10.99835602,
            lng: 77.01502627
        },
        zoom: 11
    };
    const handleChangeSelectedType = (event) => {
        setSelectedType(event.target.value);
    };


    const table_head = ['SN', 'User', 'User_Id', 'Alarm', 'Current_kW', 'Plant_Capacity', 'Plant_Location'];

    const table_data = [
        {
            "SN": '1',
            "User": 'Sooraj',
            "User_Id": '23',
            "Alarm": 'Yes',
            "Current_kW": '5',
            "Plant_Capacity": '10',
            "Plant_Location": 'Location 1',

        },
        {
            "SN": '2',
            "User": 'John',
            "User_Id": '42',
            "Alarm": 'No',
            "Current_kW": '8',
            "Plant_Capacity": '15',
            "Plant_Location": 'Location 2',

        },
        {
            "SN": '3',
            "User": 'Alice',
            "User_Id": '55',
            "Alarm": 'Yes',
            "Current_kW": '3',
            "Plant_Capacity": '12',
            "Plant_Location": 'Location 3',

        },
        // Add more data objects as needed
    ];



    const [currentPage, setCurrentPage] = useState(1);
    const [recordsPerPage] = useState(2);

    const indexOfLastRecord = currentPage * recordsPerPage;
    const indexOfFirstRecord = indexOfLastRecord - recordsPerPage;
    const currentRecords = table_data.slice(indexOfFirstRecord, indexOfLastRecord);
    const nPages = Math.ceil(table_data.length / recordsPerPage)

    const chart_data = [
        { energy: 100 },
        { energy: 150 },
        { energy: 200 }
    ];
    const labels = ['date1', 'date2', 'date3'];



    return (
        <Box>
            <CssBaseline />
            <Stack rowGap={spacing(1)}>
                <Grid container spacing={spacing(1)}>
                    <Grid item lg={3} md={3} sm={6} xs={12}>
                        <InverterList Title={'Total Users'} Value={6} />
                    </Grid>
                    <Grid item lg={3} md={3} sm={6} xs={12}>
                        <InverterList Title={'Total Inverters'} Value={14} />
                    </Grid>
                    <Grid item lg={3} md={3} sm={6} xs={12}>
                        <InverterList Title={'Live Inverters'} Value={9} />
                    </Grid>
                    <Grid item lg={3} md={3} sm={6} xs={12}>
                        <InverterList Title={'Offline Inverters'} Value={2} />
                    </Grid>
                </Grid>
                <Card>
                    <CardActions sx={{ flex: 1 }}>
                        <Stack rowGap={spacing(1)} flex={1}>
                            <Typography py={spacing(1)} variant='body2'>Last Day Kwh vs Todays Kwh</Typography>
                            <Stack direction={'row'} columnGap={spacing(1)} alignItems={'center'}>
                                <input type='date' className='searchField' />
                                <Cached fontSize='small' />
                            </Stack>
                            <BarChart />
                        </Stack>

                    </CardActions>
                </Card>

            </Stack>
        </Box>
    )
}

export default KwhGraph
