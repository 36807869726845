// ColorPaletteDialog.js

import React, { useContext, useEffect, useState } from 'react';
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Button,
    FormControl,
    ToggleButtonGroup,
    ToggleButton,
    Container,
    useTheme,
} from '@mui/material';
import { AppThemeContext } from '../../context/AppThemeContext';

const ColorPaletteDialog = ({ open, onClose, onSelectColors }) => {
    const { setPrimaryColor, PrimaryColor } = useContext(AppThemeContext);
    const [alignment, setAlignment] = useState(PrimaryColor);

    const handleChange = (event, newAlignment) => {
        if (newAlignment) {
            localStorage.setItem('UserTheme', newAlignment);
            setPrimaryColor(newAlignment);
            setAlignment(newAlignment);
        }
    };

    const handleDone = () => {
        onClose();
    };

    const colorGroup = {
        Color1: "#25476A",
        Color2: "#03A9F4",
        Color3: "#574476",
        Color5: '#356E35',
        Color8: "#F29F67",
        Color9: "#F87060",
        Color10: "#A6C48A",
        Color11: "#A63A50",
        Color12: "#1B998B",
        Color13: "#304D30",
        Color14: "#352F44",
        Color15: "#E74646",
        Color16: "#EC7272",
        Color17: "#8E3200",
        Color18: "#388E3C",
        Color19: "#561050",
        Color20: "#016A70"
    };

    return (
        <Dialog open={open} component={Container} maxWidth='md' onClose={onClose}>
            <DialogTitle>Choose Color Palette</DialogTitle>
            <DialogContent sx={{}}>
                <FormControl fullWidth>
                    <ToggleButtonGroup
                        color="primary"
                        value={alignment}
                        exclusive
                        onChange={handleChange}
                        aria-label="Platform"
                    >
                        {Object.entries(colorGroup).map(([key, value]) => (
                            <ToggleButton sx={{ bgcolor: `${value}` }} value={value} key={key}>
                                {key}
                            </ToggleButton>
                        ))}
                    </ToggleButtonGroup>
                </FormControl>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleDone} >
                    Done
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default ColorPaletteDialog;
